.participants {
  display: flex;
  flex-wrap: nowrap;
  /* grid-template-columns: repeat(var(--grid-size), 1fr);
  grid-gap: 20px; */
  margin: 10px;
  height: 58vh;
  padding: 10px;
}

.participants img{
  border-radius: 50%; 
  height: 120px; 
  width: 120px;
}
.doctor_patient_title{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  margin-left: 15px;
  line-height: 5px;
}

/* @media (max-width: 800px) { */
  @media (max-width: 450px) {
  .participants {
    /* grid-template-columns: repeat(var(--grid-col-size), 1fr);
    grid-template-rows: repeat(var(--grid-row-size), 1fr); */
    display: flex;
    flex-wrap: wrap;
    height: 30vh;
    margin-bottom: 250px;
  }
}