.back-to-top button{
    position: fixed;
    width: 5%;
    left: 1%;
    bottom: 40px;
    height: 62px;
    /* font-size: 3rem; */
    z-index: 1;
    cursor: pointer;
    border-radius: 40px;
    border: 2px solid #ffff;
}

.back-to-top button:hover{
    transform: scale(1.1);
}

@media only screen and (max-width: 900px) {
    .back-to-top button{
        position: fixed;
        width: 15%;
        left: 1%;
        bottom: 40px;
        height: 62px;
        /* font-size: 3rem; */
        z-index: 1;
        cursor: pointer;
        border-radius: 40px;
        border: 2px solid #ffff;
    }
}