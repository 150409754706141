@import url("https://fonts.maateen.me/kalpurush/font.css");
.main-screen {
  width: 100%;
  height: 74vh;
  background: #ffffff;
}
.main-screen-doctor {
  width: 50%;
  height: 74vh;
  background: #ffffff;
}
body {
  font-family: "Kalpurush", Arial, sans-serif !important;
}
.wrapper {
  width: 100%;
}

.footer {
  height: 10vh;
}
.doctor_prescription_form {
  margin-top: 40px;
  /* margin-left: 65px; */
  /* margin-left: 0%;
    margin-right: 0%; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.doctor_prescription_form h5 {
  text-align: left;
}

@media (max-width: 450px) {
  .doctor_prescription_form {
    width: 95%;
    margin: 10px;
  }
}
