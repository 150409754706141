@import url(https://fonts.maateen.me/kalpurush/font.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.meeting-footer{
    /* background: #202124;  */
    height: 100%;    
    display: -webkit-flex;    
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.meeting-icons{
    color: white;
    /* border-radius: 50%; */
    /* background:#3c4043; */
    /* border-radius: 50%; */
    background-image: linear-gradient(#82165B, #04050D);
    width: 40px;
    height: 40px;
    margin: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
}

.active {
  /* background-color:   #ea4335; */
  background-image: linear-gradient(#82165B, #04050D);
}

div[disabled]
{
  pointer-events: none;
  opacity: 0.3;
}
.modalStyle {
  margin-top: 10%;
  margin-left: 35%;
  margin-right: 35%;
  border: 1px solid #ccc8c8;
  border-radius: 40px;
  padding: 30px 10px 20px 10px;
  background-color: white;
  outline: none;
}

.feedback_text {
  text-decoration: underline;
  -webkit-text-decoration-color: rgb(233, 30, 223);
          text-decoration-color: rgb(233, 30, 223);
  text-align: center;
  text-underline-offset: 5px;
  font-weight: bold;
}
.rating {
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  margin-top: 10%;
  -webkit-transform: scale(1.4, 1.4) !important;
          transform: scale(1.4, 1.4) !important;
}
.modal_form {
  margin-top: 10px;
  width: 140%;
  margin-left: -20%;
}
.modal_form h6 {
  font-weight: bold;
}
.modal_form button {
  margin-top: 5px;
  border-radius: 7px;
  border: 1px solid #ffb903;
  background-color: #ffb903;
  margin: 10px;
  font-weight: bold;
  padding: 0px 12px 0px 12px;
}

@media only screen and (min-width: 1497px) {
}
@media only screen and (min-width: 1685px) {
}
@media only screen and (min-width: 1798px) {
  .modalStyle {
    /* height: 425px; */
    /* margin-top: 12%; */
    margin-left: 35%;
    margin-right: 35%;
    padding: 30px 10px 20px 10px;
  }

  .feedback_text {
    text-underline-offset: 5px;
    font-size: 27px;
    font-weight: bold;
  }
  .rating {
    margin-top: 12%;
    -webkit-transform: scale(1.9, 1.9) !important;
            transform: scale(1.9, 1.9) !important;
  }
  .modal_form {
    margin-top: 10px;
    width: 140%;
    margin-left: -20%;
  }
  .modal_form h6 {
    font-size: 22px;
    font-weight: bold;
  }
  .modal_form textarea {
    height: 100px;
  }
  .modal_form button {
    margin-top: 5px;
    margin: 25px;
    padding: 4px 20px 0px 20px;
    font-size: 20px;
  }
}
@media only screen and (min-width: 1920px) {
}
@media only screen and (min-width: 2049px) {
  .modalStyle {
    /* height: 490px; */
    /* margin-top: 12%; */
    margin-left: 35%;
    margin-right: 35%;
    padding: 35px 10px 20px 10px;
  }

  .feedback_text {
    text-underline-offset: 5px;
    font-size: 31px;
    font-weight: bold;
  }
  .rating {
    margin-top: 12%;
    -webkit-transform: scale(2.2, 2.2) !important;
            transform: scale(2.2, 2.2) !important;
  }
  .modal_form {
    margin-top: 10px;
    width: 140%;
    margin-left: -20%;
  }
  .modal_form h6 {
    font-size: 25px;
    font-weight: bold;
  }
  .modal_form textarea {
    height: 120px;
  }
  .modal_form button {
    margin-top: 5px;
    margin: 25px;
    padding: 5px 22px 0px 22px;
    font-size: 23px;
  }
}
@media only screen and (min-width: 2697px) {
}
@media only screen and (min-width: 4046px) {
}
@media only screen and (max-width: 1205px) {
  .modalStyle {
    /* height: 490px; */
    margin-top: 20%;
    margin-left: 25%;
    margin-right: 25%;
    padding: 35px 10px 10px 10px;
  }
}
@media only screen and (max-width: 769px) {
  .modalStyle {
    /* height: 490px; */
    margin-top: 30%;
    margin-left: 15%;
    margin-right: 15%;
    padding: 35px 0px 10px 0px;
  }
}
@media only screen and (max-width: 428px) {
  .modalStyle {
    /* height: 490px; */
    margin-top: 30%;
    margin-left: 5%;
    margin-right: 5%;
    padding: 35px 10px 10px 10px;
  }

  .feedback_text {
    text-underline-offset: 5px;
    font-size: 25px;
    font-weight: bold;
  }
  .rating {
    margin-top: 7%;
    -webkit-transform: scale(1.7, 1.7) !important;
            transform: scale(1.7, 1.7) !important;
  }
  .modal_form {
    margin-top: 20px;
    width: 100%;
    margin-left: 0%;
  }
  .modal_form h6 {
    font-size: 20px;
    margin-top: 10px;
  }
  .modal_form textarea {
    height: 100px;
  }
  .modal_form button {
    margin-top: 0px;
    margin: 15px;
    padding: 5px 22px 2px 22px;
    font-size: 20px;
  }
}
@media only screen and (max-width: 377px) {
}

.participants {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  /* grid-template-columns: repeat(var(--grid-size), 1fr);
  grid-gap: 20px; */
  margin: 10px;
  height: 58vh;
  padding: 10px;
}

.participants img{
  border-radius: 50%; 
  height: 120px; 
  width: 120px;
}
.doctor_patient_title{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  margin-left: 15px;
  line-height: 5px;
}

/* @media (max-width: 800px) { */
  @media (max-width: 450px) {
  .participants {
    /* grid-template-columns: repeat(var(--grid-col-size), 1fr);
    grid-template-rows: repeat(var(--grid-row-size), 1fr); */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    height: 30vh;
    margin-bottom: 250px;
  }
}
.card{
    /* background: #3c4043; */
    background-image: linear-gradient(#7D1558, #04050D);
    border-radius: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;    
    height: 100%;
}


  .video{
    /* background-color: #353b48; */
    background-color: #7D1558;
    /* background-image: linear-gradient(#7D1558, #04050D); */
    height: 100%;
    width: 100%;
    position: relative;
    object-fit: cover;
    border-radius: 5px;
  }

  .participant {
    /* background-color: #353b48; */
    background-color: #7D1558;
    /* background-image: linear-gradient(#7D1558, #04050D); */
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 5px;
    min-height: 200px;
    margin: 5px;
  }

  .muted {
    color: #fff;
    right: 10px;
    position: absolute;
    top: 10px;
    background: #353b48;
    border-radius: 50%;
    height: 22px;
    width: 22px!important;
  }

  .hide {
    display: none;
  }

  .avatar {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    font-size: 150px;
    font-family: roboto, arial;
    color: white;
    text-align: center;
    background: orange;
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-transform: uppercase;
  }

  .name {
    color: white;
    bottom: 10px;
    position: absolute;
    left: 20px;
  }

  @media (max-width: 800px) {
    .participant{
      min-height: 0px;
    }
    .avatar {
      width: 80px;
      height: 80px;
      font-size: 50px;
    }
  }
.main-screen {
  width: 100%;
  height: 74vh;
  background: #ffffff;
}
.main-screen-doctor {
  width: 50%;
  height: 74vh;
  background: #ffffff;
}
body {
  font-family: "Kalpurush", Arial, sans-serif !important;
}
.wrapper {
  width: 100%;
}

.footer {
  height: 10vh;
}
.doctor_prescription_form {
  margin-top: 40px;
  /* margin-left: 65px; */
  /* margin-left: 0%;
    margin-right: 0%; */
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}
.doctor_prescription_form h5 {
  text-align: left;
}

@media (max-width: 450px) {
  .doctor_prescription_form {
    width: 95%;
    margin: 10px;
  }
}

/* .headerBox{
    height: auto;
    background-image: linear-gradient(to right, #121843 , #8B1861);
} */

/* .doctor_slider{
  width: 1100px;
} */

/* .headerBox {
  height: 110vh;
  position: relative;
  background-image: url("../../media/cover-01.png");
  background-size: cover;
}
.headerBox input {
  padding-left: 33px;
  padding-right: 33px;
  border-radius: 5px;
  border: 1px solid white;
  text-align: center;
  outline: none;
}
.headerBox img {
  height: 90px;
  cursor: pointer;
} */

.headerbox_eachDoctor img {
  height: 80px;
}
.doctorForm_headerbox img {
  height: 80px;
}
.page_wrap {
  min-height: calc(100vh - 180px);
}
.headerBox2_logo {
  height: 80px;
  cursor: pointer;
}
.headerBox2_logo_patientProfile {
  height: 80px;
  cursor: pointer;
}
.headerBox2 #drowdownImg {
  height: 17px;
  margin-top: -5px;
}
/* .headerBox3 {
  height: 740px;
  position: relative;
  background-image: url("../../media/cover-01.png");
  background-size: cover;
}
.headerBox3 img {
  height: 120px;
  cursor: pointer;
} */
.headerBox_doctor_portal_home {
  height: 110vh;
  /* height: 740px; */
  position: relative;
  background-image: url(/static/media/cover-doctor.87303693.png);
  background-size: cover;
}
.headerBox_doctor_portal_home img {
  /* height: 100px; */
}
/* .searchButton {
  width: 35px;
  height: 28px;
  border: 1px solid #ffcc43;
  background: #ffcc43;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  margin-left: -3px;
}

.searchButton i:hover {
  transform: scale(-1, 1);
}
.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  left: 0;
  right: 0;
  width: 20%;
  left: 71%;
  background-color: #fff;
  height: 300px;
  overflow-y: auto;
}

.autocomplete-items div {
  cursor: pointer;
  padding: 2px;
}

.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

.items_serial {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #d4d4d4;
  line-height: 4px;
}
.items_serial img {
  height: 85px;
  width: 80px;
}
.items_serial_info {
  text-align: left;
}
.items_serial_info #name {
  margin-top: 7px;
  font-size: 18px;
  color: #bd095a;
} */

.landingLogoDoctorPortal {
  /* margin-left: 480px; */
  /* text-align: center; */
}

/* .landingLogo {
  margin-left: -50px;
} */

.dropdown_container ul {
  margin-left: -10px;
  margin-right: -10px;
}

.menu_button {
  border-radius: 5px;
  width: 100%;
  font-size: small;
  margin-top: -7px;
  /* margin-left: -10px;
  margin-right: -10px; */
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #d4377d;
  color: white;
  border: 1px solid #d4377d;
  /* padding-left: 60px;
  padding-right: 10px; */
}

.doctorDivision {
  width: 75%;
}
.doctorDivisionProfile {
  width: 50%;
}

.doctorlist {
  background-color: #cc0a61;
  padding-top: 5px;
  padding-bottom: 0.5px;
  color: #ffffffff;
  text-align: center;
  border-radius: 10px;
  margin-left: 40%;
  margin-right: 50%;
  width: 23%;
}
.doctor_benefit {
  background-color: #cc0a61;
  padding-top: 6px;
  /* padding-bottom: 0.5px; */
  color: #ffffffff;
  text-align: center;
  border-radius: 10px;
  /* margin-left: 40%; */
  /* margin-right: 50%; */
  width: 23%;
  /* display: flex; */
  /* justify-content: center; */

  margin: 0;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.doctor_portal_login {
  background-color: #cc0a61;
  padding-top: 6px;
  /* padding-bottom: 0.5px; */
  color: #ffffffff;
  text-align: center;
  border-radius: 10px;
  /* margin-left: 40%; */
  /* margin-right: 50%; */
  width: 17%;
  /* display: flex; */
  /* justify-content: center; */
  margin: 0;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.doctor_title_flex {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-left: 14px;
  padding-right: 14px;
}
.doctor_title_flex_left {
  border-bottom: 2px solid #cd1d64;
}
.doctor_title_flex_right {
  background-image: linear-gradient(#74175a, #04050d);
  color: #ffb903;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
#dropdown-basic {
  background-image: linear-gradient(#74175a, #04050d);
  color: #ffb903;
  width: 92%;
}
#dropdown-basic-profile {
  /* background-image: linear-gradient(#42275a , #734b6d);
  color: white; */
  /* background-image: linear-gradient(#004e92, #000428); 
  color: white; */
  /* background-image: linear-gradient(#dc2430, #7b4397); 
  color: white; */
  /* background-image: linear-gradient(#cc2b5e  , #753a88);
  color: white; */
  /* background-image: linear-gradient(#aa076b, #61045f); 
  color: white;
  border: 1px solid #aa076b; */
  color: #cc0a61;
  font-weight: bolder;
  font-size: 20px;
  width: 92%;
  padding: 0 0 0 0;
  margin-right: -30px;
}
#dropdown-basic-profile img {
  height: 24px;
  margin-right: 8px;
  margin-top: -5px;
}

.doctor-main-container {
  width: 100%;
  /* width: 87%; */
}
/* .slick-slide{
  margin: 0 0px;
} */

/* .slick-list{
  margin:  -5px;
} */

/* card design */
.doctor_home {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  /* align-items: center; */
}
.doctor_home_img {
  width: auto;
  height: 550px;
  margin-right: 220px;
  margin-left: -100px;
  position: relative;
}
.doctor_reg_card {
  margin-left: 0px;
  margin-top: 150px;
}
.doctor_reg_card_container {
  background-image: linear-gradient(#04050d, #74175a);
  border-radius: 15px;
  width: 120%;
  color: #ffffffff;
  padding-bottom: 17px;
}
.doctor_reg_card_content {
  padding: 15px;
  height: 300px;
}

.doctor_reg_card_content img {
  height: 120px;
  width: 120px;
  margin-top: 20px;
  margin-bottom: 8px;
}
.doctor_reg_card_content p {
  font-size: 18px;
}
.doctor_reg_card_content button {
  font-size: 20px;
}
.doctor_reg_card_content button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.doctor_login_card_container {
  background-image: linear-gradient(#04050d, #74175a);
  border-radius: 15px;
  /* width: 25%; */
  color: #ffffffff;
  /* padding-bottom: 17px; */
}

.doctor_login_card_content {
  padding: 15px;
  height: 400px;
}

.doctor_login_card_content img {
  height: 100px;
  width: 100px;
}

.doctor_login_card_content input {
  width: 80%;
  height: 35px;
  border-radius: 8px;
  border: 1px solid white;
}

.doctor_login_card_content Button {
  margin-top: 20px;
  margin-bottom: 10px;
  height: 35px;
}

.doctor_forget_password {
  text-decoration: underline;
  -webkit-text-decoration-color: rgba(255, 255, 255, 0.486);
          text-decoration-color: rgba(255, 255, 255, 0.486);
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}
.doctor-description {
  text-decoration: underline;
  -webkit-text-decoration-color: rgba(173, 87, 87, 0.486);
          text-decoration-color: rgba(173, 87, 87, 0.486);
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

.doctor_card_container {
  background-image: linear-gradient(#74175a, #04050d);
  /* height: 500px; */
  /* margin-bottom: 10px; */
  /* margin-left: 100px; */
  /* align-items: center; */
  /* margin: 10px; */
  border-radius: 15px;
  width: 90%;
  /* width: 95%; */
  /* margin-right: 30px !important; */
  color: #ffffffff;
}
.doctor_content {
  padding: 15px;
  height: 309px;
}
.doctor_flex {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}
.doctor-reg {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  /* align-items: center; */
}
.doctor-reg img {
  width: auto;
  height: 600px;
  margin-left: 90px;
}
.doctor-reg-form {
  text-align: left;
  margin-right: 220px;
}
.doctor-reg-form h4 {
  border-bottom: 1px solid #cc0a61;
  width: 89%;
}
.doctor-reg-form input {
  width: 140%;
  margin-bottom: 10px;
  background-color: #e8abc8af;
  color: black;
  border: 1px solid #da5490;
  border-radius: 5px;
}
.doctor-reg-form input:focus {
  outline: none;
  box-shadow: 0 0 10px #719ece;
}
/* .doctor-reg-form input:hover { */
/* width: 140%;
  margin-bottom: 10px;
  background-color: #e8abc7;
  color: white;
  opacity: 65%;
  border: 2px solid #BD095A;
  border-radius: 5px; */
/* margin-bottom: 100px; */
/* } */
.doctor-reg-form p {
  font-weight: bold;
  margin-bottom: 2px;
}

.doctor_desc {
  -webkit-justify-content: center;
          justify-content: center;
  text-align: justify;
  font-size: 10px;
  margin-top: 4%;
}
.doctor_flex_image img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border: 2px solid #ffb903;
  margin-right: 30px;
}
.session_bounce {
  background: #ffb903;
  border: 2px solid #ffb903;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  -webkit-animation: bounce 0.3s infinite alternate;
  animation: bounce 0.3s infinite alternate;
}
.session_bounce:hover {
  background: #ffb903;
  border: 3px solid #ffaf00;
}
@-webkit-keyframes bounce {
  to {
    -webkit-transform: scale(1.2);
  }
}
@keyframes bounce {
  to {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

.doctor_flex_details {
  text-align: left;
  /* margin-left: 5px; */
  /* margin-right: 5px; */
}
/* .doctor_flex_details h2{
  font-size: 25px;
}
.doctor_flex_details p{
  font-size: 15px;
  margin-top: -13px;
}
.doctor_flex_details div{
  margin-top: -8px;
}
.doctor_flex_details_time{
  font-size: 13px;
} */

.each_doctor_image {
  width: 250px;
  height: 250px;
  /* padding: 20px; */
  padding-left: 10px;
  padding-right: 10px;
  border-top: 10px solid #6d1655;
  border-bottom: 10px solid #04050d;
  background-image: linear-gradient(#6d1655, #04050d),
    linear-gradient(#6d1655, #04050d);
  background-size: 10px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  border-radius: 10px;
}
.online_appoinment_form {
  text-align: left;
}
.online_appoinment_form h4 {
  font-size: 15px;
  font-weight: 700;
}
.online_appoinment_form input:focus {
  outline: none;
  box-shadow: 0 0 10px #719ece;
}
.online_appoinment_form textarea:focus {
  outline: none;
  box-shadow: 0 0 10px #719ece;
}
.online_appoinment_form button {
  font-size: 20px;
  font-weight: bold;
  left: 20%;
  margin-left: 30%;
  border-radius: 5px;
  border: 1px solid #ffc107;
  background-color: #ffc107;
}
.doctorForm_info {
  text-align: left;
}
.doctorForm_info_name {
  font-size: 25px;
}
.doctorForm_info_designation {
  font-size: 15px;
  margin-bottom: 0rem;
}
.doctorForm_rating {
  margin-left: -3px;
}
.doctorForm_info_time {
  font-size: 15px;
}
.doctorForm_info_time span {
  font-size: 12px;
}
.doctorForm_info_fees {
  border: 1px solid #cc0a61;
  background-color: #cc0a61;
  border-radius: 5px;
  color: white;
}
.doctorForm_info_session {
  font-size: 12px;
  margin-top: 3px;
}

/* Countdown timer */
.countdown_container img {
  height: 120px;
}
.countdown_container h2 {
  margin-top: 40px;
}
.countdown_timer {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-image: linear-gradient(rgb(9, 6, 16), rgb(112, 22, 87));
  color: #ffffffff;
  margin-left: 43%;
  margin-right: 42%;
  padding-top: 75px;
  margin-top: 30px;
}
/* countdown timer end */

/* FOOTER */
/* .footer {
  height: 300px;
  background-image: linear-gradient(to right, #121843, #89175f);
} */
.container8_full_width {
  background-image: linear-gradient(to right, #121843, #89175f);
  /* margin: 0 -9999rem;
  padding: 0rem 9999rem; */

  /* padding: 0 100px;
  width: 100%; */
  margin: 0;
  padding-top: 30px;
  font-family: "Poppins";
  font-weight: bold;
}
.container8 {
  display: grid;
  height: 100%;
  /* grid-template-columns: 10% 8% 20% 15% 22% 15% 10%; */
  /* grid-template-columns: auto 140px 259px 170px 220px 200px auto; */
  /* grid-template-columns: auto 140px 259px 220px 220px 200px auto; */
  grid-template-columns: auto 399px 220px 250px auto;
  grid-template-rows: auto;
  color: #ffff;
}

.container9 {
  display: grid;
  grid-template-columns: 140px 259px;
  grid-template-rows: auto;
  color: #ffff;
}

.app {
  text-align: left;
  margin-top: 35px;
}
.app .app_text {
  text-align: left;
  font-size: 16px;
  margin-top: 10px;
}

.app img {
  width: 30%;
  /* height: 40px; */
  margin-right: 2%;
}

.app img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.container8 h6 {
  font-size: small;
}
.find_us {
  text-align: left;
}
.container8 .social-icon img {
  width: 20px;
  padding-right: 5px;
}
.container8 .social-icon img:hover {
  cursor: pointer;
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}
.address p {
  text-align: left;
}
.address h6 {
  text-align: left;
  padding-right: 20px;
}
.container8 .serial-tag {
  text-align: left;
}
.serial-tag h6 {
  cursor: pointer;
  /* text-decoration: underline; */
  color: white;
}
.serial-tag h6:hover {
  -webkit-transform: scale(1, 1.2);
          transform: scale(1, 1.2);
}
#carebox_tech {
  text-decoration: none;
}
.POD p {
  text-align: left;
}
.POD img {
  height: auto;
  border-radius: 6px;
  width: 100%;
}
.POD img:hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}
.rights {
  line-height: 1px;
  text-align: center;
  color: #ffff;
  padding-bottom: 2px;
  padding-top: 2px;
}
.rights h5 {
  font-size: small;
}
.rights p {
  font-size: 14px;
}
/* Footer end */

.patient_waitingList_card_container {
  background-image: linear-gradient(#04050d, #74175a);
  /* border-radius: 15px; */
  /* width: 25%; */
  color: #ffffffff;
  /* padding-bottom: 17px; */
}
.patient_waitingList_card_content {
  padding: 15px;
  height: auto;
}
/* .header_session_id {
  background-color: #cc0a61;
  padding-top: 5px;
  color: #ffffffff;
  text-align: center;
  border-radius: 5px;
  width: 10%;
  height: 30px;
}

.header_patient_name {
  background-color: #cc0a61;
  padding-top: 5px;
  color: #ffffffff;
  text-align: center;
  border-radius: 5px;
  width: 15%;
  height: 30px;
}

.header_patient_problem {
  background-color: #cc0a61;
  padding-top: 5px;
  color: #ffffffff;
  text-align: center;
  border-radius: 5px;
  width: 25%;
  height: 30px;
}

.header_doctor_date {
  background-color: #cc0a61;
  padding-top: 5px;
  color: #ffffffff;
  text-align: center;
  border-radius: 5px;
  width: 12%;
  height: 30px;
}

.header_doctor_action {
  background-color: #cc0a61;
  padding-top: 5px;
  color: #ffffffff;
  text-align: center;
  border-radius: 5px;
  width: 33%;
  height: 30px;
} */

.waitingList_info_container {
  background-color: white;
  color: black;
  width: 100%;
  margin-top: 15px;
  border-radius: 10px;
  height: auto;
  padding: 10px 0 10px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.waitingList_info_container h6 {
  margin: 0 0 0 0;
}
.waitingList_info_container Button {
  font-size: small;
  width: 40%;
  background-color: #ffb903;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #ffb903;
  height: 30px;
  padding-top: 4px;
}
.waitingList_info_container .download_button {
  font-size: small;
  width: 100%;
  background-color: #ffb903;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #ffb903;
  height: 30px;
  padding-top: 4px;
}
.waitingList_info_container Button:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.treatment_desc_header div {
  background-color: #cc0a61;
  padding-top: 7px;
  border-radius: 5px;
}
.treatment_desc_header2 div {
  background-color: #cc0a61;
  padding-top: 7px;
  border-radius: 5px;
}

.treatment_desc {
  background-color: white;
  padding-top: 7px;
  margin-top: 15px;
  border-radius: 8px;
}
.treatment_desc h6 {
  color: black;
}
.desc_button h6 {
  margin-left: 25%;
}
.desc_button img {
  height: 18px;
}
.treatment_desc_detailshow {
  width: 100%;
  background-color: rgb(194, 194, 194);
  color: black;
  border-radius: 10px;
  padding: 10px 5px 10px 5px;
}
.treatment_desc_detailshow button {
  background-color: #ffb903;
  border-radius: 5px;
  border: 1px solid #ffb903;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.treatment_desc_detailshow button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.patient_problem_detailshow {
  background-color: white;
  margin-top: 7px;
  border-radius: 8px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 5px;
}
.prescription_textarea {
  /* margin-top: 17px; */

  background-color: white;
  margin-top: 7px;
  border-radius: 8px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 5px;
}
.treatment_doctor_info {
  text-align: left;
}
.treatment_doctor_info h5 {
  margin-left: 40px;
}
.treatment_doctor_info #name,
#phone,
#age {
  background-color: #f3c6da;
  width: 80%;
  padding: 3px;
  border-radius: 5px;
}

Form h3 {
  font-weight: 600;
  border-bottom: 1px solid #bd095a;
  width: 52%;
}
Form input {
  border: 1px solid #fab1d2;
  background-color: #ffe0ee;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 6px;
}

Form textarea {
  border: 1px solid #fab1d2;
  background-color: #ffe0ee;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 6px;
}

.custom-file-upload input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #fab1d2;
  background-color: #ffe0ee;
  display: inline-block;
  padding: 20px 18px;
  cursor: pointer;
  border-radius: 8px;
  font-weight: bold;
}
.doctor_flex_details h2 {
  font-size: 25px;
}
.doctor_flex_details_designation {
  font-size: 15px;
  margin-top: -13px;
}
.doctor-fees {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  background-color: #cc0a61;
  margin-top: -8px;
  /* text-align: left; */
  padding: 0 4px;
}
.doctor_flex_details_time {
  font-size: 13px;
}

.eachDoctor_details {
  text-align: left;
}
.eachDoctor_designation {
  font-size: 18px;
  margin-top: -16px;
}
.eachDoctor_rating {
  margin-top: -15px;
  margin-left: -3px;
}
.eachDoctor_time {
  font-size: 14px;
}
.charge_fifty {
  background-color: #cc0a61;
  /* padding: 4px; */
  margin-left: -3px;
  font-size: 18px;
  color: #ffffffff;
  border: 2px solid #ffffffff;
  border-radius: 7px;
}
.session_25 {
  margin-top: 2px;
  font-size: 12px;
}
.eachDoctor_description {
  text-decoration: underline;
  -webkit-text-decoration-color: #ffb903;
          text-decoration-color: #ffb903;
  text-decoration-thickness: 1px;
  margin-top: 20px;
  text-underline-offset: 3px;
}
.eachDoctor_desc p {
  font-size: 13px;
}
.session_button {
  font-size: 20px;
  font-weight: 600;
  margin-left: 30%;
  height: 42px;
  color: black;
  border-radius: 5px;
  border: 1px solid #ffc107;
  background-color: #ffc107;
}

/* .doctor_reg_section p{
  font-size: 30px;
} */

@media only screen and (min-width: 1497px) {
}
@media only screen and (min-width: 1685px) {
}
@media only screen and (min-width: 1798px) {
  /* .headerBox {
    height: 1000px;
  }
  .headerBox img {
    height: 120px;
    margin-left: -180px;
  } */
  .headerBox2_logo_patientProfile {
    height: 120px;
    margin-left: -150px;
  }
  /* .navbar_input {
    margin-right: -100px;
  }
  .headerBox input {
    height: 40px;
    font-size: 24px;
    margin-top: 7px;
  }
  .searchButton {
    width: 45px;
    height: 40px;
    font-size: 24px;
  }
  .autocomplete-items {
    width: 21%;
    left: 1268px;
    height: 385px;
  }
  .devider {
    border-bottom: 1px solid #d4d4d4;
  }
  .items_serial {
    border-bottom: none;
    line-height: 4px;
    margin-left: 150px;
  }
  .items_serial img {
    height: 115px;
    width: 115px;
  }
  .items_serial_info {
    margin-left: -15px;
  }
  .items_serial_info #name {
    margin-top: 10px;
    font-size: 23px;
  }
  .items_serial_info p {
    font-size: 20px;
    line-height: 10px;
  } 
  .headerBox_doctor_portal_home {
    height: 1000px;
  }
  .headerBox_doctor_portal_home img {
    height: 120px;
    margin-left: -180px;
  }*/
  .doctorlist {
    padding-top: 17px;
    padding-bottom: 5px;
    margin-left: 38%;
    margin-right: 38%;
    width: 25%;
  }
  .doctorDivision {
    width: 100%;
    margin-left: -120px;
  }
  .doctorDivisionProfile {
    width: 50%;
    margin-right: -115px;
  }
  #dropdown-basic {
    width: 94%;
    font-size: 23px;
  }
  #dropdown-basic-profile {
    /* background-image: linear-gradient(#42275a , #734b6d);
    color: white; */
    /* background-image: linear-gradient(#004e92, #000428); 
    color: white; */
    /* background-image: linear-gradient(#dc2430, #7b4397); 
    color: white; */
    /* background-image: linear-gradient(#cc2b5e  , #753a88);
    color: white; */
    /* background-image: linear-gradient(#aa076b, #61045f); 
    color: white;
    border: 1px solid #aa076b; */
    color: #cc0a61;
    font-weight: bolder;
    font-size: 28px;
    width: 92%;
    padding: 0 0 0 0;
    margin-right: -20px;
  }
  #dropdown-basic-profile img {
    height: 30px;
    margin-right: 8px;
    margin-top: -5px;
  }
  #dropdown_item {
    width: 92%;
    font-size: 23px;
    margin-left: 15px;
  }
  .doctorDivision_dropdownMenu {
    width: 65%;
  }
  .doctorDivisionProfile_dropdownMenu {
    width: 95%;
  }
  .gynae_container {
    margin-left: -100px !important;
  }
  .doctor-main-container {
    width: 118%;
  }
  .doctor_title_flex_left {
    margin-left: -120px;
    font-size: 25px;
  }
  .doctor_title_flex_right {
    margin-right: -120px;
    font-size: 22px;
  }
  .doctor_content {
    width: 100%;
    padding: 15px;
    height: 410px;
  }
  .doctor_card_container {
    width: 90%;
  }
  .doctor_flex {
    /* margin-top: 10px; */
  }
  .doctor_flex_image img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin-right: 30px;
  }
  .doctor_flex_details {
    text-align: left;
  }
  .doctor_flex_details h2 {
    font-size: 35px;
  }
  .doctor_flex_details_designation {
    font-size: 22px;
    margin-top: -13px;
  }
  /* .doctor-fees {
    width: 90%;
    margin-top: -8px;
    padding-left: 4px;
  } */
  .doctor-fees h5 {
    font-size: 28px;
  }
  .doctor_flex_details_time {
    font-size: 20px;
  }
  .doctor_flex_details Button {
    font-size: 22px;
  }
  .doctor_desc {
    font-size: 16px;
    padding: 10px;
  }
  .doctor_desc p {
    font-size: 15px;
  }
  /* FOOTER */
  .container8_full_width {
    /* margin: 0 -9999rem;
  padding: 0rem 9999rem; */

    /* padding: 0 100px; */
    /* width: 100%; */
    margin: 0;
    padding-top: 40px;
  }

  /* .container8 {
    height: 400px;
    grid-template-columns: auto 190px 340px 280px 270px auto;
  } */

  .container8 {
    height: 400px;
    grid-template-columns: auto 530px 280px 270px auto;
  }

  .container9 {
    grid-template-columns: 190px 340px;
  }

  .app {
    margin-top: 30px;
  }
  .app .app_text {
    font-size: 20px;
    margin-top: 15px;
  }

  .app img {
    width: 35%;
    margin-right: 2%;
  }

  .container8 h6 {
    font-size: 18px;
  }
  .container8 p {
    font-size: 22px;
  }
  .container8 .social-icon img {
    width: 25px;
    padding-right: 5px;
  }
  .address h6 {
    padding-right: 25px;
  }
  .POD img {
    height: auto;
    width: 130%;
  }
  .rights {
    line-height: 13px;
    padding-bottom: 2px;
    padding-top: 2px;
  }
  .rights h5 {
    font-size: 17px;
  }
  .rights p {
    font-size: 19px;
  }
  /* Footer end */

  /* Each Doctor Details */
  .headerbox_eachDoctor img {
    height: 110px;
    /* margin-left: -85px; */
  }
  .eachDoctor_details {
    text-align: left;
  }
  .eachDoctor_details h2 {
    font-size: 40px;
  }
  .eachDoctor_designation {
    font-size: 23px;
    margin-top: -16px;
  }
  .eachDoctor_rating {
    margin-top: -15px;
    margin-left: 80px;
    -webkit-transform: scale(1.3, 1.3) !important;
            transform: scale(1.3, 1.3) !important;
  }
  .eachDoctor_time {
    font-size: 18px;
  }
  .charge_fifty {
    /* padding: 4px; */
    margin-top: 10px;
    margin-left: -3px;
    font-size: 25px;
  }
  .session_25 {
    margin-top: 3px;
    font-size: 16px;
  }
  .eachDoctor_description {
    text-decoration: underline;
    -webkit-text-decoration-color: #ffb903;
            text-decoration-color: #ffb903;
    text-decoration-thickness: 1px;
    margin-top: 35px;
    text-underline-offset: 3px;
    font-size: 28px;
  }
  .eachDoctor_desc p {
    font-size: 18px;
  }
  .session_button {
    font-size: 27px;
    margin-left: 30%;
    height: 55px;
  }
  .each_doctor_image {
    width: 350px;
    height: 350px;
    /* padding: 20px; */
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-content: center;
            align-content: center;
    padding-left: 15px;
    padding-right: 15px;
    border-top: 15px solid #6d1655;
    border-bottom: 15px solid #04050d;
    background-size: 15px 100%;
    background-position: 0 0, 100% 0;
    background-repeat: no-repeat;
    border-radius: 10px;
  }
  /* Each Doctor Details End */

  /* Doctor Form */
  .doctorForm_headerbox img {
    height: 120px;
    margin-left: -50px;
  }
  .doctorForm_headerbox p {
    font-size: 25px;
    margin-right: -40px;
  }
  .online_appoinment_form {
    text-align: left;
    margin-left: -50px;
  }
  .online_appoinment_form h3 {
    font-size: 35px;
    width: 55%;
    margin-bottom: 20px;
  }
  .online_appoinment_form h4 {
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
  }
  .online_appoinment_form label {
    font-size: 22px;
  }
  .online_appoinment_form input {
    font-size: 20px;
    height: 40px;
    width: 130%;
  }

  .online_appoinment_form #Male,
  #Female,
  #Other {
    /* font-size: 20px; */
    height: 20px;
    width: 20px;
    margin-bottom: 20px;
  }
  .online_appoinment_form textarea {
    font-size: 20px;
    width: 130%;
    height: 130px;
  }
  .online_appoinment_form button {
    margin-top: 15px;
    font-size: 25px;
    left: 20%;
    margin-left: 45%;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
  }
  .doctorForm_info {
    text-align: left;
  }
  .doctorForm_info_name {
    margin-left: 5px;
    font-size: 37px;
  }
  .doctorForm_info_designation {
    font-size: 20px;
    margin-left: 5px;
    margin-bottom: 0rem;
    margin-top: 12px;
  }
  .doctorForm_rating {
    margin-top: 10px;
    margin-left: 70px;
    -webkit-transform: scale(1.4, 1.4) !important;
            transform: scale(1.4, 1.4) !important;
    margin-bottom: 6px;
  }
  .doctorForm_info_time {
    margin-left: 5px;
    font-size: 20px;
  }
  .doctorForm_info_time span {
    font-size: 18px;
  }
  .doctorForm_info_fees {
    margin-left: 5px;
    color: white;
    font-size: 25px;
    margin-top: 10px;
  }
  .doctorForm_info_session {
    font-size: 17px;
    margin-left: 5px;
    margin-top: 5px;
  }
  /* Doctor Form End */

  /* Countdown timer */
  .countdown_container img {
    height: 150px;
  }
  .countdown_container h2 {
    margin-top: 50px;
    font-size: 40px;
  }
  .countdown_timer {
    height: 260px;
    width: 260px;
    border-radius: 50%;
    margin-left: 43%;
    margin-right: 42%;
    padding-top: 100px;
    margin-top: 30px;
    font-size: 55px;
  }
  /* countdown timer end */

  /* Doctor Portal Home  */
  /* .headerBox_doctor_portal_home {
    height: 1000px;
  }
  .headerBox_doctor_portal_home img {
    height: 150px;
    margin-left: -180px;
  } */
  .doctor_benefit {
    padding: 10px 0 1px 0;
    width: 22%;
    /* margin: 0; */
    position: relative;
    margin-top: 20px;
    margin-bottom: -15px;
  }
  .doctor_benefit h3 {
    font-size: 40px;
  }
  .doctor_home_img {
    /* width: auto; */
    height: 700px;
    margin-right: 320px;
    margin-left: -100px;
    /* position: relative; */
  }
  .doctor_reg_card {
    /* margin-left: 0px; */
    /* margin-right: 160px;
    margin-top: 250px; */
  }
  .doctor_reg_card_container {
    height: 450px;
    border-radius: 15px;
    width: 110%;
    color: #ffffffff;
    padding-bottom: 17px;
  }
  .doctor_reg_card_content {
    padding: 15px;
    /* height: 300px; */
  }
  .doctor_reg_card_content img {
    height: 180px;
    width: 180px;
    margin-top: 20px;
    margin-bottom: 14px;
  }
  .doctor_reg_card_content p {
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 35px;
  }
  .doctor_reg_card_content button {
    font-size: 27px;
  }
  /* Docto Portal Home end */

  /* DOCTOR REGISTRATION FORM */
  /* .headerBox3 {
    height: 1000px;
    position: relative;
    background-image: url("../../media/cover-01.png");
    background-size: cover;
  }
  .headerBox3 img {
    height: 150px;
    margin-left: -160px;
    cursor: pointer;
  } */
  .doctor-reg img {
    height: 800px;
    margin-left: 90px;
  }
  .doctor-reg-form {
    margin-right: 400px;
  }
  .doctor-reg-form input {
    height: 35px;
    font-size: 20px;
  }
  .doctor-reg-form h4 {
    font-size: 32px;
    width: 86%;
  }
  .doctor-reg-form p {
    font-size: 22px;
  }
  .doctor-reg-form button {
    margin-left: 135px !important;
    font-size: 22px;
  }
  /* DOCTOR REGISTRATION FORM END  */

  /* PATIENT WAITING LIST */
  .headerBox2 #logo {
    height: 120px;
    margin-left: -100px;
  }
  .headerBox2 p {
    font-size: 26px;
    margin-right: -100px;
  }
  .headerBox2 #drowdownImg {
    height: 25px;
    margin-top: -5px;
  }
  .dropdown_container ul {
    /* margin-left: -10px;
    margin-right: -10px; */
  }

  .menu_button {
    border-radius: 5px;
    width: 100%;
    font-size: 16px;
    margin-top: -7px;
    /* margin-left: -10px;
    margin-right: -10px; */
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #d4377d;
    color: white;
    border: 1px solid #d4377d;
    /* padding-left: 60px;
    padding-right: 10px; */
  }
  .doctor_portal_login h3 {
    font-size: 35px;
    margin: 0 0 0 0;
  }
  .waiting_patient_text h6 {
    font-size: 22px;
  }
  .waitingList_info_container {
    padding: 15px 0 15px 0;
  }

  .waitingList_info_container Button {
    font-size: 17px;
    width: 40%;
    border-radius: 5px;
    height: 40px;
    padding-top: 4px;
  }
  .waitingList_info_container .download_button {
    font-size: 17px;
    /* width: 40%; */
    border-radius: 5px;
    height: 40px;
    padding-top: 4px;
  }
  /* PATIENT WAITING LIST END */
}
@media only screen and (min-width: 1920px) {
  /* .headerBox {
    height: 1040px;
  }
  .headerBox3 {
    height: 1050px;
  }
  .headerBox img {
    height: 120px;
    margin-left: -200px;
  }
  .headerBox3 img {
    height: 160px;
    margin-left: -210px;
    cursor: pointer;
  } */
  .headerBox2_logo {
    height: 120px;
    margin-left: -150px;
  }
  .headerBox2_logo_patientProfile {
    height: 120px;
    margin-left: -200px;
  }
  /* .navbar_input {
    margin-right: -120px;
  }
  .headerBox input {
    height: 40px;
    border-radius: 5px;
    border: 1px solid white;
    text-align: center;
  }

  .autocomplete-items {
    width: 380px;
    left: 1348px;
    height: 455px;
  }
  .items_serial {
    border-bottom: none;
    line-height: 4px;
    margin-left: 165px;
  }
  .items_serial img {
    height: 135px;
    width: 135px;
  }
  .items_serial_info {
    margin-left: -30px;
  }
  .items_serial_info #name {
    margin-top: 10px;
    font-size: 27px;
  }
  .items_serial_info p {
    font-size: 23px;
    line-height: 16px;
  } */

  /* .headerBox_doctor_portal_home {
    height: 1040px;
  }
  .headerBox_doctor_portal_home img {
    height: 120px;
    margin-left: -200px;
  } */
  .doctorlist {
    padding-top: 17px;
    padding-bottom: 5px;
    margin-left: 38%;
    margin-right: 38%;
    width: 25%;
  }
  .doctorDivision {
    width: 100%;
    margin-left: -160px;
  }
  .doctorDivisionProfile {
    width: 50%;
    margin-right: -127px;
  }
  #dropdown-basic {
    width: 94%;
    font-size: 23px;
  }

  #dropdown-basic-profile {
    /* background-image: linear-gradient(#42275a , #734b6d);
    color: white; */
    /* background-image: linear-gradient(#004e92, #000428); 
    color: white; */
    /* background-image: linear-gradient(#dc2430, #7b4397); 
    color: white; */
    /* background-image: linear-gradient(#cc2b5e  , #753a88);
    color: white; */
    /* background-image: linear-gradient(#aa076b, #61045f); 
    color: white;
    border: 1px solid #aa076b; */
    color: #cc0a61;
    font-weight: bolder;
    font-size: 30px;
    width: 92%;
    padding: 0 0 0 0;
    margin-right: -20px;
  }
  #dropdown-basic-profile img {
    height: 34px;
    margin-right: 8px;
    margin-top: -5px;
  }

  #dropdown_item {
    width: 92%;
    font-size: 26px;
    margin-left: 15px;
  }
  .doctorDivision_dropdownMenu {
    width: 65%;
  }
  .doctorDivisionProfile_dropdownMenu {
    width: 95%;
  }
  .doctor-main-container {
    width: 124%;
  }
  .doctor_title_flex_left {
    margin-left: -155px;
    font-size: 25px;
  }
  .doctor_title_flex_right {
    margin-right: -135px;
    font-size: 25px;
  }
  .gynae_container {
    margin-left: -150px !important;
  }
  .doctor_content {
    width: 100%;
    padding: 15px;
    height: 450px;
  }
  .doctor_card_container {
    width: 90%;
  }
  .doctor_flex {
    margin-top: 10px;
  }
  .doctor_flex_image img {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    margin-right: 30px;
  }
  .doctor_flex_details {
    text-align: left;
  }

  .doctor_flex_details h2 {
    font-size: 40px;
  }

  .doctor_flex_details h2 {
    font-size: 40px;
  }
  .doctor_flex_details_designation {
    font-size: 22px;
    margin-top: -13px;
  }
  /* .doctor-fees {
    width: fit-content;
    margin-top: -8px;
    padding: 0 4px;
  } */
  .doctor-fees h5 {
    font-size: 30px;
  }
  .doctor_flex_details_time {
    font-size: 22px;
  }
  .doctor_flex_details Button {
    font-size: 25px;
  }
  .doctor_desc {
    font-size: 16px;
    padding: 10px;
  }

  /* PATIENT WAITING LIST */
  .headerBox2 #logo {
    height: 120px;
    margin-left: -150px;
  }
  .headerBox2 p {
    font-size: 26px;
    margin-right: -150px;
  }
  .headerBox2 #drowdownImg {
    height: 25px;
    margin-top: -5px;
  }
  .dropdown_container ul {
    /* margin-left: -10px;
    margin-right: -10px; */
  }

  .menu_button {
    border-radius: 5px;
    width: 100%;
    font-size: 17px;
    margin-top: -7px;
    /* margin-left: -10px;
    margin-right: -10px; */
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #d4377d;
    color: white;
    border: 1px solid #d4377d;
    /* padding-left: 60px;
    padding-right: 10px; */
  }
  .doctor_portal_login h3 {
    font-size: 38px;
    margin: 0 0 0 0;
  }
  .waiting_patient_text h6 {
    font-size: 24px;
  }
  .waitingList_info_container {
    padding: 17px 0 17px 0;
  }

  .waitingList_info_container Button {
    font-size: 20px;
    width: 40%;
    border-radius: 5px;
    height: 40px;
    padding-top: 4px;
  }
  .waitingList_info_container .download_button {
    font-size: 20px;
    /* width: 40%; */
    border-radius: 5px;
    height: 40px;
    padding-top: 4px;
  }
  /* PATIENT WAITING LIST END */
}
@media only screen and (min-width: 2049px) {
  /* .headerBox {
    height: 120vh;
  }
  .headerBox img {
    height: 150px;
    margin-left: -300px;
  }
  .headerBox3 {
    height: 1100px;
  } */
  .headerBox2_logo_patientProfile {
    height: 120px;
    margin-left: -300px;
  }
  /* .headerBox_doctor_portal_home{
    height: 85vh;
  } */
  /* .headerBox3 img {
    height: 170px;
    margin-left: -260px;
  } */
  /* .navbar_input {
    margin-right: -190px;
  } */
  /* .headerBox input {
    height: 40px;
    font-size: 24px;
    margin-top: 7px;
  } */
  /* .searchButton {
    width: 45px;
    height: 41px;
    font-size: 24px;
  }
  .autocomplete-items {
    width: 380px;
    left: 1468px;
    height: 418px;
  }
  .items_serial {
    border-bottom: none;
    line-height: 4px;
    margin-left: 215px;
  }
  .items_serial img {
    height: 125px;
    width: 125px;
  }
  .items_serial_info {
    margin-left: -90px;
  }
  .items_serial_info #name {
    margin-top: 10px;
    font-size: 27px;
  }
  .items_serial_info p {
    font-size: 24px;
    line-height: 13px;
  } */

  .doctorlist {
    padding-top: 17px;
    padding-bottom: 5px;
    margin-left: 38%;
    margin-right: 38%;
    width: 25%;
  }
  .doctorDivision {
    width: 100%;
    margin-left: -160px;
  }
  #dropdown-basic {
    width: 94%;
    font-size: 23px;
  }

  #dropdown-basic-profile {
    /* background-image: linear-gradient(#42275a , #734b6d);
    color: white; */
    /* background-image: linear-gradient(#004e92, #000428); 
    color: white; */
    /* background-image: linear-gradient(#dc2430, #7b4397); 
    color: white; */
    /* background-image: linear-gradient(#cc2b5e  , #753a88);
    color: white; */
    /* background-image: linear-gradient(#aa076b, #61045f); 
    color: white;
    border: 1px solid #aa076b; */
    color: #cc0a61;
    font-weight: bolder;
    font-size: 30px;
    width: 92%;
    padding: 0 0 0 0;
    margin-right: -20px;
  }
  #dropdown-basic-profile img {
    height: 34px;
    margin-right: 8px;
    margin-top: -5px;
  }

  #dropdown_item {
    width: 92%;
    font-size: 26px;
    margin-left: 15px;
  }
  .doctorDivision_dropdownMenu {
    width: 65%;
  }
  .doctorDivisionProfile_dropdownMenu {
    width: 100%;
  }
  .doctor-main-container {
    width: 124%;
  }
  .doctor_title_flex_left {
    margin-left: -155px;
    font-size: 25px;
  }
  .doctor_title_flex_right {
    margin-right: -135px;
    font-size: 25px;
  }
  .doctor_content {
    width: 100%;
    padding: 15px;
    height: 450px;
  }
  .doctor_card_container {
    width: 90%;
  }
  .doctor_flex {
    margin-top: 10px;
  }
  .doctor_flex_image img {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    margin-right: 30px;
  }
  .doctor_flex_details {
    text-align: left;
  }

  .doctor_flex_details h2 {
    font-size: 40px;
  }
  .doctor_flex_details_designation {
    font-size: 22px;
    margin-top: -13px;
  }
  /* .doctor-fees {
    width: 90%;
    margin-top: -8px;
    padding-left: 4px;
  } */
  .doctor-fees h5 {
    font-size: 30px;
  }
  .doctor_flex_details_time {
    font-size: 22px;
  }
  .doctor_flex_details Button {
    font-size: 25px;
  }
  .doctor_desc {
    font-size: 16px;
    padding: 10px;
  }

  /* FOOTER */
  .container8_full_width {
    margin: 0;
    padding-top: 40px;
  }
  /* .container8 {
    height: 450px;
    grid-template-columns: auto 220px 380px 300px 290px auto;
  } */

  .container8 {
    height: 450px;
    grid-template-columns: auto 600px 300px 290px auto;
  }

  .container9 {
    grid-template-columns: 220px 380px;
  }

  .app {
    margin-top: 45px;
  }
  .app .app_text {
    font-size: 22px;
    margin-top: 20px;
  }

  .app img {
    width: 35%;
    margin-right: 2%;
  }

  .container8 h6 {
    font-size: 20px;
  }
  .container8 p {
    font-size: 24px;
  }
  .container8 .social-icon img {
    width: 28px;
    padding-right: 5px;
  }
  .address h6 {
    padding-right: 25px;
  }
  .serial-tag h6 {
    line-height: 27px;
  }
  .POD img {
    height: auto;
    width: 130%;
  }
  .rights {
    line-height: 13px;
    padding-bottom: 2px;
    padding-top: 2px;
  }
  .rights h5 {
    font-size: 19px;
  }
  .rights p {
    font-size: 22px;
  }
  /* Footer end */

  /* Each Doctor Details */
  .headerbox_eachDoctor img {
    height: 130px;
    margin-left: -80px;
  }
  .eachDoctor_info {
    margin-left: -80px;
  }
  .eachDoctor_details {
    text-align: left;
  }
  .eachDoctor_details h2 {
    font-size: 45px;
  }
  .eachDoctor_designation {
    font-size: 25px;
    margin-top: -16px;
  }
  .eachDoctor_rating {
    margin-top: -10px;
    margin-left: 160px;
    -webkit-transform: scale(1.7, 1.7) !important;
            transform: scale(1.7, 1.7) !important;
  }

  .eachDoctor_time {
    margin-top: 5px;
    font-size: 20px;
  }
  .charge_fifty {
    /* padding: 4px; */
    margin-top: 13px;
    margin-left: -3px;
    font-size: 28px;
  }
  .session_25 {
    margin-top: 3px;
    font-size: 18px;
  }
  .eachDoctor_description {
    text-decoration: underline;
    -webkit-text-decoration-color: #ffb903;
            text-decoration-color: #ffb903;
    text-decoration-thickness: 1px;
    margin-top: 40px;
    text-underline-offset: 3px;
    font-size: 30px;
  }
  .eachDoctor_desc p {
    font-size: 20px;
  }
  .session_button {
    font-size: 30px;
    margin-left: 30%;
    height: 60px;
  }
  .each_doctor_image {
    width: 400px;
    height: 400px;
    /* padding: 20px; */
    margin-left: 100px;
    padding-left: 15px;
    padding-right: 15px;
    border-top: 15px solid #6d1655;
    border-bottom: 15px solid #04050d;
    background-size: 15px 100%;
    background-position: 0 0, 100% 0;
    background-repeat: no-repeat;
    border-radius: 10px;
  }
  /* Each Doctor Details End */

  /* Doctor Form */
  .doctorForm_headerbox img {
    height: 140px;
    margin-left: -100px;
  }
  .doctorForm_headerbox p {
    font-size: 30px;
    margin-right: -140px;
  }
  .online_appoinment_form {
    text-align: left;
    margin-left: -100px;
  }
  .online_appoinment_form h3 {
    font-size: 40px;
    width: 63%;
    margin-bottom: 20px;
  }
  .online_appoinment_form h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
  }
  .online_appoinment_form label {
    font-size: 24px;
  }
  .online_appoinment_form input {
    font-size: 23px;
    height: 50px;
    width: 140%;
  }

  .online_appoinment_form #Male,
  #Female,
  #Other {
    /* font-size: 20px; */
    height: 24px;
    width: 24px;
    margin-bottom: 20px;
  }
  .online_appoinment_form textarea {
    font-size: 20px;
    width: 140%;
    height: 140px;
  }
  .online_appoinment_form button {
    margin-top: 20px;
    font-size: 30px;
    font-weight: bold;
    left: 20%;
    margin-left: 40%;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #ffc107;
    background-color: #ffc107;
  }
  .doctorForm_info {
    text-align: left;
  }
  .doctorForm_info_name {
    margin-left: 100px;
    font-size: 40px;
  }
  .doctorForm_info_designation {
    font-size: 25px;
    margin-left: 100px;
    margin-bottom: 0rem;
  }
  .doctorForm_rating {
    margin-top: 10px;
    margin-left: 170px;
    -webkit-transform: scale(1.6, 1.6) !important;
            transform: scale(1.6, 1.6) !important;
    margin-bottom: 8px;
  }
  .doctorForm_info_time {
    margin-left: 100px;
    font-size: 25px;
  }
  .doctorForm_info_time span {
    font-size: 22px;
  }
  .doctorForm_info_fees {
    margin-left: 100px;
    color: white;
    font-size: 30px;
  }
  .doctorForm_info_session {
    font-size: 20px;
    margin-left: 100px;
    margin-top: 3px;
  }
  /* Doctor Form End */

  /* Countdown timer */
  .countdown_container img {
    height: 170px;
  }
  .countdown_container h2 {
    margin-top: 70px;
    font-size: 50px;
  }
  .countdown_timer {
    height: 300px;
    width: 300px;
    border-radius: 50%;
    margin-left: 43%;
    margin-right: 42%;
    padding-top: 110px;
    margin-top: 50px;
    font-size: 60px;
  }
  /* countdown timer end */

  /* Doctor Portal Home  */
  /* .headerBox_doctor_portal_home {
    height: 1140px;
  }
  .headerBox_doctor_portal_home img {
    height: 150px;
    margin-left: -300px;
  } */
  .doctor_benefit {
    padding: 10px 0 1px 0;
    width: 22%;
    /* margin: 0; */
    position: relative;
    margin-top: 20px;
    margin-bottom: -15px;
  }
  .doctor_benefit h3 {
    font-size: 40px;
  }
  .doctor_home_img {
    /* width: auto; */
    height: 900px;
    margin-right: 320px;
    margin-left: -100px;
    /* position: relative; */
  }
  .doctor_reg_card {
    margin-left: 0px;
    margin-right: 160px;
    margin-top: 250px;
  }
  .doctor_reg_card_container {
    height: 470px;
    border-radius: 15px;
    width: 120%;
    color: #ffffffff;
    padding-bottom: 17px;
  }
  .doctor_reg_card_content {
    padding: 15px;
    /* height: 300px; */
  }
  .doctor_reg_card_content img {
    height: 180px;
    width: 180px;
    margin-top: 20px;
    margin-bottom: 14px;
  }
  .doctor_reg_card_content p {
    font-size: 27px;
    margin-top: 10px;
    margin-bottom: 35px;
  }
  .doctor_reg_card_content button {
    font-size: 32px;
  }
  /* Docto Portal Home end */

  /* PATIENT WAITING LIST */
  .headerBox2 #logo {
    height: 150px;
    margin-left: -200px;
  }
  .headerBox2 p {
    font-size: 30px;
    margin-right: -200px;
  }
  .headerBox2 #drowdownImg {
    height: 25px;
    margin-top: -5px;
  }
  .dropdown_container ul {
    /* margin-left: -10px;
    margin-right: -10px; */
  }

  .menu_button {
    border-radius: 5px;
    width: 100%;
    font-size: 19px;
    margin-top: -7px;
    /* margin-left: -10px;
    margin-right: -10px; */
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #d4377d;
    color: white;
    border: 1px solid #d4377d;
    /* padding-left: 60px;
    padding-right: 10px; */
  }
  .doctor_portal_login h3 {
    font-size: 40px;
    margin: 0 0 0 0;
  }
  .waiting_patient_text h6 {
    font-size: 25px;
  }
  .waitingList_info_container {
    padding: 19px 0 19px 0;
  }

  .waitingList_info_container Button {
    font-size: 22px;
    width: 40%;
    border-radius: 5px;
    height: 40px;
    padding-top: 4px;
  }
  .waitingList_info_container .download_button {
    font-size: 22px;
    /* width: 40%; */
    border-radius: 5px;
    height: 40px;
    padding-top: 4px;
  }
  /* PATIENT WAITING LIST END */
}
@media only screen and (min-width: 2697px) {
}
@media only screen and (min-width: 4046px) {
}
@media only screen and (max-width: 1205px) {
  .doctor_flex_details_time {
    margin-bottom: 10px;
    width: -webkit-max-content;
    width: max-content;
  }
  /* .doctor-fees {
    width: max-content;
    padding-right: 2px;
  } */
  .doctor_flex_details h2 {
    width: 100%;
  }
  .doctor_flex_details button {
    width: -webkit-max-content;
    width: max-content;
  }
  .doctor_flex_image img {
    /* width: 110px;
    height: 110px;
    border-radius: 50%; */
    /* margin-right: 10px; */
  }
}

@media only screen and (max-width: 1024px) {
  .doctorlist {
    margin-left: 35%;
    margin-right: 35%;
    width: 30%;
  }

  .doctorDivision {
    width: 100%;
  }

  .doctorDivisionProfile {
    margin-right: 30px;
  }

  .doctor_flex_image img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .page_wrap {
    min-height: calc(100vh - 290px);
  }
}

@media only screen and (max-width: 769px) {
  .doctor_flex_image img {
    width: 120px;
    height: 120px;
    margin-right: 5px;
    margin-left: 5px;
  }
  .page_wrap {
    min-height: calc(100vh - 290px);
  }
}

@media only screen and (max-width: 767px) {
  .doctorDivisionProfile {
    margin-right: -250px;
    margin-top: -40px;
  }

  .doctor_flex_image img {
    width: 80px;
    height: 80px;
    margin-right: 5px;
    margin-left: 5px;
  }

  .page_wrap {
    min-height: calc(100vh - 290px);
  }
}
@media only screen and (max-width: 428px) {
  /* .headerBox {
    height: 500px;
    position: relative;
    background-image: url("../../media/cover-01.png");
    background-size: cover;
  }
  .headerBox img {
    height: 80px;
    cursor: pointer;
    margin: 0 0 0 0;
  }
  .headerBox input {
    width: 81%;
  }
  .searchButton {
    width: 45px;
    height: 28px;
  }
  .autocomplete-items {
    width: 55%;
    left: 170px;
    height: 280px;
  }
  .devider {
    border-bottom: 1px solid #d4d4d4;
  }
  .items_serial {
    border-bottom: none;
    line-height: 2px;
  }
  .items_serial img {
    height: 80px;
    width: 70px;
  }
  .items_serial_info {
    margin-left: -3px;
  }
  .items_serial_info #name {
    margin-top: 10px;
    font-size: 19px;
  }
  .items_serial_info p {
    font-size: 16px;
  } */
  .doctorlist {
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
    padding-top: 8px;
    margin-bottom: -40px;
    margin-top: -20px;
  }
  .doctorDivision {
    width: 140%;
    margin-left: 67px;
    margin-top: -10px;
  }
  .doctorDivisionProfile {
    margin-right: -20px;
    margin-top: 15px;
  }
  .doctor_flex_image img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    margin-right: 10px;
  }
  /* FOOTER */
  .container8_full_width {
    margin: 0;
    padding-left: 10px;
  }
  .container8 {
    display: grid;
    height: 300px;
    grid-template-columns: auto 79px 110px 100px 90px auto;
    grid-template-rows: auto;
    color: #ffff;
  }

  .container8 {
    height: 300px;
    grid-template-columns: auto 189px 100px 90px auto;
  }

  .container9 {
    grid-template-columns: 79px 110px;
  }

  .app {
    margin-top: 0px;
  }
  .app .app_text {
    font-size: 16px;
    margin-top: 10px;
  }

  .app img {
    width: 40%;
    margin-right: 2%;
  }

  /* Footer end */

  /* Each Doctor Details */
  .headerbox_eachDoctor input {
    margin-top: 20px;
    border-radius: 5px;
    border: 1px solid black;
    margin-left: 5px;
  }
  .each_doctor_image {
    height: 300px;
    width: 300px;
    margin-left: 45px;
    margin-bottom: 10px;
  }
  .eachDoctor_row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .eachDoctor_info {
    text-align: center;
  }
  .eachDoctor_details h2 {
    font-size: 40px;
  }
  .eachDoctor_designation {
    font-size: 23px;
    margin-top: -16px;
  }
  .eachDoctor_rating {
    margin-top: -15px;
    margin-left: 38%;
    margin-right: 38%;
    -webkit-transform: scale(1.3, 1.3) !important;
            transform: scale(1.3, 1.3) !important;
  }
  .eachDoctor_time {
    font-size: 18px;
  }
  .charge_fifty {
    margin-top: 10px;
    margin-left: -3px;
    font-size: 25px;
  }
  .session_25 {
    margin-top: 3px;
    font-size: 16px;
  }
  .eachDoctor_description {
    text-decoration: underline;
    -webkit-text-decoration-color: #ffb903;
            text-decoration-color: #ffb903;
    text-decoration-thickness: 1px;
    margin-top: 35px;
    text-underline-offset: 3px;
    font-size: 28px;
  }
  .eachDoctor_desc p {
    text-align: justify;
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .session_button {
    font-size: 27px;
    margin-left: 0px;
    height: 55px;
  }
  /* Each Doctor Details End */

  /* Doctor Form */
  .doctorForm_headerbox img {
    height: 90px;
  }
  .doctorForm_headerbox p {
    font-size: 25px;
    margin-top: 35px;
  }
  .online_appoinment_form {
    margin-top: -50px !important;
  }
  .online_appoinment_form h3 {
    font-size: 28px;
    width: 50%;
    text-align: center;
    margin-left: 25%;
    margin-right: 25%;
  }
  .online_appoinment_form h4 {
    font-size: 20px;
  }
  .online_appoinment_form label {
    font-size: 20px;
  }
  .online_appoinment_form input {
    font-size: 20px;
    height: 37px;
  }
  .online_appoinment_form #Male,
  #Female,
  #Other {
    height: 20px;
    width: 20px;
    margin-bottom: 15px;
    margin-left: -25px;
  }
  .online_appoinment_form textarea {
    font-size: 20px;
    height: 100px;
  }
  .online_appoinment_form button {
    font-size: 20px;
    border-radius: 5px;
    padding: 10px;
  }
  .doctorForm_info {
    display: none;
  }
  /* Doctor Form End */

  /* Countdown timer */
  .countdown_container img {
    height: 140px;
  }
  .countdown_container h2 {
    margin-top: 40px;
    font-size: 35px;
  }
  .countdown_timer {
    height: 240px;
    width: 240px;
    border-radius: 50%;
    color: #ffffffff;
    margin-left: 20%;
    margin-right: 20%;
    padding-top: 85px;
    margin-top: 30px;
  }
  .time {
    font-size: 45px;
  }
  /* countdown timer end */

  /* DOCTOR PORTAL HOME  */
  .headerBox_doctor_portal_home {
    height: 30vh;
    /* height: 500px; */
    /* position: relative;
    background-image: url("../../media/cover-doctor.png");
    background-size: cover; */
  }
  /* .headerBox_doctor_portal_home img {
    height: 80px;
    cursor: pointer;
    margin-left: 15px;
  } */
  .doctor_benefit {
    width: 50%;
  }
  .doctor_home_img {
    margin-left: 150px;
  }
  .doctor_reg_card_container {
    margin-top: -100px;
    margin-left: -25px;
  }
  .doctor_reg_section {
    margin-top: -30px;
    margin-left: -10px;
    margin-bottom: -50px;
  }
  Section .doctor_reg_card_container {
    margin-top: 20px;
  }
  /* DOCTOR PORTAL HOME END  */

  /* DOCTOR REGISTRATION FORM */
  /* .headerBox3 {
    height: 500px;
    position: relative;
    background-image: url("../../media/cover-01.png");
    background-size: cover;
    margin-bottom: -90px;
  }
  .headerBox3 img {
    height: 90px;
    margin-left: 10px;
    cursor: pointer;
  } */
  .doctor-reg img {
    display: none;
  }
  .doctor-reg-form {
    margin-top: 100px;
    margin-left: 20px;
    width: 130%;
  }
  .doctor-reg-form h4 {
    font-weight: 700;
    border-bottom: 2px solid #bd095a;
    padding-bottom: 5px;
    width: 130%;
    font-size: 28px;
    margin-bottom: 20px;
  }
  .doctor-reg-form p {
    font-size: 22px;
    width: 200%;
  }
  .doctor-reg-form input {
    width: 207% !important;
    height: 35px;
    font-size: 22px;
    font-weight: bold;
  }
  .doctor-reg-form button {
    width: 100%;
    margin-top: 20px;
    font-size: 22px;
  }
  /* DOCTOR REGISTRATION FORM END  */

  /* PATIENT WAITING LIST */
  .headerBox2 #logo {
    height: 80px;
  }
  .headerBox2 p {
    font-size: 20px;
    margin-top: 20px;
  }
  .headerBox2 #drowdownImg {
    height: 20px;
    margin-top: -5px;
  }
  .menu_button {
    border-radius: 5px;
    width: 100%;
    font-size: 16px;
    margin-top: -7px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .doctor_portal_login {
    width: 50%;
    padding-bottom: 3px;
  }
  .doctor_portal_login h3 {
    font-size: 26px;
    margin: 0 0 0 0;
  }
  .waiting_patient_text {
    margin: 0 5px;
    /* margin: 2px 2px 2px 2px; */
    /* margin: 0 0 0 0; */
    /* padding: 0 0 0 0; */
  }
  .treatment_desc_header #id,
  #name,
  #date {
    width: auto;
    padding-left: 3px;
    padding-right: 3px;
  }
  .treatment_desc_header #remove {
    display: none;
  }
  .waitingList_info_container {
    display: grid;
    grid-gap: 5px;
  }
  .waitingList_info_container #remove {
    display: none;
  }
  .waitingList_info_container Button {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    line-height: 1;
  }
  .waitingList_info_container .download_button {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    line-height: 1;
  }
  .patient_waitingList_card_container {
    margin-bottom: -100px;
  }
  .waiting_patient_text {
    margin-top: -25px;
  }
  /* PATIENT WAITING LIST END */

  /* TREATMENT DESCRIPTION */
  .treatment_desc_header2 #name2,
  #date2 {
    width: auto;
    padding-left: 7%;
    padding-right: 7%;
  }
  .treatment_desc_header2 #id2 {
    width: auto;
    padding-left: 8px;
    padding-right: 8px;
  }
  .treatment_desc_header2 #remove {
    display: none;
  }
  .treatment_desc #remove {
    display: none;
  }
  .summary {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  .summary #name3,
  #date3 {
    /* width: auto; */
    /* margin-left: 30px; */
    /* margin-right: -20px; */
    /* padding: 0 5px; */
  }
  /* TREATMENT DESCRIPTION END */
}

/* @media only screen and (max-width: 377px) {  */
@media only screen and (max-width: 394px) {
  /* .headerBox_doctor_portal_home {
    height: 500px;
    position: relative;
    background-image: url("../../media/cover-doctor.png");
  } */

  .doctor_flex_image img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .POD img {
    width: 80%;
    margin-left: -20px;
  }
  .doctor_flex_details_time {
    margin-bottom: 0px;
  }

  /* .navbar_input {
    margin-right: -20px;
    margin-left: -10px;
  }
  .headerBox input {
    height: 30px;
    font-size: 16px;
    margin-top: 7px;
  }
  .searchButton {
    width: 25px;
    height: 30px;
    margin-top: 10px;
  }
  .searchButton i {
    font-size: 14px;
    margin-left: -2px;
  }
  .autocomplete-items {
    width: 54%;
    left: 167px;
    margin-top: -2px;
    height: 261px;
  }
  .devider {
    border-bottom: 1px solid #d4d4d4;
  } */
  /* .items_serial {
    border-bottom: none;
    line-height: 4px;
    margin-left: 150px;
  } */
  /* .items_serial img {
    height: 70px;
    width: 70px;
  } */
  /* .items_serial_info {
    margin-left: -15px;
  } 
  .items_serial_info #name {
    font-size: 16px;
  }
  .items_serial_info p {
    font-size: 13px;
    line-height: 0px;
  }*/

  .particularDoctor_flex_details_time {
    margin-bottom: 0px;
  }
  .each_doctor_image {
    height: 280px;
    width: 280px;
    margin-left: 12%;
    margin-right: 12%;
    margin-bottom: 10px;
  }
}

/* Small devices (landscape phones, 576px and up) */
/* @media (min-width: 576px) {} */

/* // Medium devices (tablets, 768px and up) */
/* @media (min-width: 768px) {} */

/* // Large devices (desktops, 992px and up) */
/* @media (min-width: 992px) {} */

/* // Extra large devices (large desktops, 1200px and up) */
/* @media (min-width: 1200px) {} */

/* @media (max-width: 575.98px) {} */

/* // Small devices (landscape phones, less than 768px) */
/* @media (max-width: 767.98px) {} */

/* // Medium devices (tablets, less than 992px) */
/* @media (max-width: 991.98px) {} */

/* // Large devices (desktops, less than 1200px) */
/* @media (max-width: 1199.98px) {} */

.cs-loader {
    /* position: absolute; */
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  
  .cs-loader-inner {
    /* transform: translateY(-50%); */
    top: 50%;
    /* position: absolute; */
    /* width: calc(100% - 200px); */
    color: rgb(53, 158, 207);
    padding: 0 100px;
    /* text-align: center; */
  }
  
  .cs-loader-inner label {
    font-size: 20px;
    opacity: 0;
    display:inline-block;
  }
  
  @keyframes lol {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-300px);
              transform: translateX(-300px);
    }
    33% {
      opacity: 1;
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
    66% {
      opacity: 1;
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
    100% {
      opacity: 0;
      -webkit-transform: translateX(300px);
              transform: translateX(300px);
    }
  }
  
  @-webkit-keyframes lol {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-300px);
    }
    33% {
      opacity: 1;
      -webkit-transform: translateX(0px);
    }
    66% {
      opacity: 1;
      -webkit-transform: translateX(0px);
    }
    100% {
      opacity: 0;
      -webkit-transform: translateX(300px);
    }
  }
  
  .cs-loader-inner label:nth-child(6) {
    -webkit-animation: lol 3s infinite ease-in-out;
    animation: lol 3s infinite ease-in-out;
  }
  
  .cs-loader-inner label:nth-child(5) {
    -webkit-animation: lol 3s 100ms infinite ease-in-out;
    animation: lol 3s 100ms infinite ease-in-out;
  }
  
  .cs-loader-inner label:nth-child(4) {
    -webkit-animation: lol 3s 200ms infinite ease-in-out;
    animation: lol 3s 200ms infinite ease-in-out;
  }
  
  .cs-loader-inner label:nth-child(3) {
    -webkit-animation: lol 3s 300ms infinite ease-in-out;
    animation: lol 3s 300ms infinite ease-in-out;
  }
  
  .cs-loader-inner label:nth-child(2) {
    -webkit-animation: lol 3s 400ms infinite ease-in-out;
    animation: lol 3s 400ms infinite ease-in-out;
  }
  
  .cs-loader-inner label:nth-child(1) {
    -webkit-animation: lol 3s 500ms infinite ease-in-out;
    animation: lol 3s 500ms infinite ease-in-out;
  }
.headerBoxHome {
  height: 110vh;
  /* height: min-content; */
  position: relative;
  background-image: url(/static/media/cover-01.8feffafb.png);
  background-size: cover;
}
.search_design {
  display: grid;
  grid-template-columns: auto auto auto;
  /* grid-template-rows: 100px; */
  /* grid-template-rows: 180px; */
  grid-template-rows: 20vh;
  /* grid-template-columns: 100%; */
  grid-row-gap: 1%;
  row-gap: 1%;
  grid-column-gap: 5%;
  -webkit-column-gap: 5%;
          column-gap: 5%;
  padding-top: 10px;
}

.landingLogo1 {
  cursor: pointer;
  height: 20vh;
  /* height: 100%; */
  margin-left: -20%;
}

.search {
  width: 100%;
  position: relative;
  display: -webkit-flex;
  display: flex;
}

.searchTerm {
  width: 100%;
  border: 1px solid #ffcc43;
  border-right: none;
  padding: 5px;
  height: 5vh;
  /* height: 32px; */
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;
}

.searchTerm:focus {
  color: #000000;
}

.searchButton1 {
  width: 5vh;
  /* height: 32px; */
  height: 5vh;
  border: 1px solid #ffcc43;
  background: #ffcc43;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 2vh;
}

.wrap {
  width: 63%;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.autocomplete-items1 {
  position: relative;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  height: 280px;
  /* height: 30vh; */
  overflow-y: auto;
  overflow-x: hidden;
  top: 100%;
  left: 0;
  right: 0;
  width: 62%;
  left: 19%;
  top: 52%;
  background-color: #fff;
  margin-top: -3vh;
}

.autocomplete-items1 div {
  cursor: pointer;
  padding: 2px;
  text-align: left;
}

.autocomplete-items1 div:hover {
  background-color: #e9e9e9;
}

.items_serial1 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  border-bottom: 1px solid #d4d4d4;
  line-height: 12px;
}

.items_serial1 img {
  height: 83px;
  width: 83px;
}

@media only screen and (min-width: 1497px) {
}
@media only screen and (min-width: 1685px) {
}
@media only screen and (min-width: 1798px) {
}
@media only screen and (min-width: 1920px) {
}
@media only screen and (min-width: 2049px) {
}
@media only screen and (min-width: 2697px) {
}
@media only screen and (min-width: 4046px) {
}


/* @media only screen and (max-width: 1205px) {
  .headerBoxHome {
    height: 80vh;
  }
  .search_design {
    grid-template-columns: 15% 15% 50%;
    grid-template-rows: 100px;
    row-gap: 0%;
    column-gap: 10%;
    padding-top: 1%;
  }
  .landingLogo1 {
    margin-top: -10px;
    margin-left: 55px;
    height: 15vh;
  }
}

@media only screen and (max-width: 995px) {
  .headerBoxHome {
    height: 65vh;
  }
}

@media only screen and (max-width: 769px) {
  .headerBoxHome {
    height: 52vh;
  }
  .search_design {
    grid-template-columns: 15% 15% 50%;
    grid-template-rows: 80px;
    row-gap: 0%;
    column-gap: 10%;
    padding-top: 1%;
  }
  .landingLogo1 {
    margin-top: -10px;
    margin-left: 25px;
    height: 12vh;
  }
}

@media only screen and (max-width: 649px) {
  .headerBoxHome {
    height: 45vh;
  }
  .search_design {
    grid-template-columns: 5% 15% 70%;
    grid-template-rows: 50px;
    row-gap: 0%;
    column-gap: 5%;
    padding-top: 1%;
  }
  .landingLogo1 {
    margin-top: -10px;
    margin-left: 25px;
    height: 10vh;
  }
  .wrap {
    margin-left: 6vh;
  }
  .autocomplete-items1 {
    margin-left: 6vh;
  }
}

@media only screen and (max-width: 550px) {
  .headerBoxHome {
    height: 36vh;
  }
} */

@media only screen and (max-width: 1024px) {
  .headerBoxHome {
    height: 34em;
  }
  .search_design {
    grid-template-columns: 15% 15% 60%;
    grid-template-rows: 80px;
    grid-row-gap: 0%;
    row-gap: 0%;
    grid-column-gap: 5%;
    -webkit-column-gap: 5%;
            column-gap: 5%;
    padding-top: 1%;
  }
  .landingLogo1 {
    margin-top: -10px;
    margin-left: 25px;
    height: 8rem;
  }
}

@media only screen and (max-width: 769px) {
  .headerBoxHome {
    height: 29rem;
  }
  .search_design {
    grid-template-columns: 15% 15% 60%;
    grid-template-rows: 60px;
    grid-row-gap: 0%;
    row-gap: 0%;
    grid-column-gap: 5%;
    -webkit-column-gap: 5%;
            column-gap: 5%;
    padding-top: 1%;
  }
  .landingLogo1 {
    margin-top: -10px;
    margin-left: 25px;
    height: 6rem;
  }
}


@media only screen and (max-width: 428px) {
  .headerBoxHome {
    /* background-image: none;
    height: auto; */
    /* height: 510px; */
    /* height: 62vh; */
    height: 30vh;
  }
  .search_design {
    /* display: list-item; */
    display: grid;
    grid-template-columns: 5% 1% 84%;
    grid-template-rows: 1px;
    grid-row-gap: 1%;
    row-gap: 1%;
    grid-column-gap: 5%;
    -webkit-column-gap: 5%;
            column-gap: 5%;
    padding-top: 1%;
  }
  .landingLogo1 {
    margin-top: -10px;
    margin-left: 15px;
    height: 60px;
  }
  .search {
    padding-top: 10px;
  }
  .searchTerm {
    padding: 5px;
    height: 28px;
  }
  .searchButton1 {
    height: 28px;
    font-size: 18px;
  }
  .wrap {
    margin-left: 5vh;
    margin-top: 4%;
  }
  .autocomplete-items1 {
    margin-left: 5vh;
    margin-top: -19px;
  }
}
@media only screen and (max-width: 377px) {
}

.slick-prev:before,
.slick-next:before {
    color: #CC0A61 !important;
    font-weight: 500; 
}
.back-to-top button{
    position: fixed;
    width: 5%;
    left: 1%;
    bottom: 40px;
    height: 62px;
    /* font-size: 3rem; */
    z-index: 1;
    cursor: pointer;
    border-radius: 40px;
    border: 2px solid #ffff;
}

.back-to-top button:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

@media only screen and (max-width: 900px) {
    .back-to-top button{
        position: fixed;
        width: 15%;
        left: 1%;
        bottom: 40px;
        height: 62px;
        /* font-size: 3rem; */
        z-index: 1;
        cursor: pointer;
        border-radius: 40px;
        border: 2px solid #ffff;
    }
}
/* .App {
  display: flex;
  width: 100vw;
  height: 100vh;
} */

/* html {
  scroll-behavior: smooth;
} */

/* body { */
  /* background: #ffffff none repeat scroll 0 0; */
  /* padding: 0 0 30rem 0; */
  /* padding-bottom: 50vh; */
  /* min-height: 100vh; */
/* } */

html {
  height: 100vh;
}
body {
  min-height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.pres_container {
  display: grid;
  height: auto;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  padding-top: 20px;
}
.registerLogo img {
  width: 15%;
  /* height: 10px; */
}
.pres_container .left_section_pres {
  padding: 50px;
}
.pres_container .left_section_pres img {
  width: 70%;
  -webkit-align-items: right;
          align-items: right;
  margin-left: 70px;
}
.pres_container .left_section_pres h1 {
  color: #bd095a;
  /* font-weight: bold; */
  text-align: left;
  margin-left: 20%;
}
.pres_container .left_section_pres h4 {
  /* font-family: 'Kalpurush', sans-serif; */
  /* text-align: left; */
  /* margin-right: 100%; */
  /* width: 50px; */
  margin-left: 20%;
  text-align: left;
}
.pres_container .right_section_pres {
  border-left: 1px solid #bd095a;
  /* padding-left: 0%; */
  /* padding-top: 10%; */
  height: 300px;
  margin-top: 100px;
}

.pres_container .right_section_pres h4 {
  margin-top: 10px;
  text-align: left;
  margin-left: 33%;
}

.pres_container .right_section_pres input {
  background-color: #bd095a;
  color: #ffffff;
  font-size: 20px;
  border-radius: 2px;
  height: 40px;
  width: 230px;
  border: 2px solid #bd095a;
}
.pres_container .right_section_pres input:focus {
  background-color: #bd095a;
  color: #ffffff;
}

.registerButton {
  border-radius: 10px;
  width: 18%;
  margin-top: 15px;
  background-image: linear-gradient(to right, #6c0534, #cc0a61);
  color: white;
  border: 0;
}

@media only screen and (min-width: 1497px) {
}
@media only screen and (min-width: 1685px) {
}
@media only screen and (min-width: 1798px) {
}
@media only screen and (min-width: 1920px) {
}
@media only screen and (min-width: 2049px) {
}
@media only screen and (min-width: 2697px) {
}
@media only screen and (min-width: 4046px) {
}
@media only screen and (max-width: 1205px) {
}
@media only screen and (max-width: 769px) {
}
@media only screen and (max-width: 428px) {
  .pres_container .left_section_pres {
    padding: 0px;
    margin-left: -75px;
    margin-top: 30px;
    margin-bottom: -70px;
  }
  .pres_container .left_section_pres img {
    width: 50%;
  }
  .pres_container .left_section_pres h1 {
    font-size: 35px;
    text-align: center;
    margin-right: 14px;
  }
  .pres_container .left_section_pres h4 {
    font-size: 23px;
    text-align: center;
  }
  .pres_container .right_section_pres {
    border-left: none;
    margin-top: 100px;
    margin-left: 15px;
  }

  .pres_container .right_section_pres h4 {
    margin-top: 10px;
    text-align: left;
    margin-left: 31%;
    font-size: 25px;
  }

  .pres_container .right_section_pres input {
    font-size: 20px;
    border-radius: 2px;
    height: 40px;
    width: 260px;
    border: 2px solid #bd095a;
    margin-left: -15px;
  }
  .pres_container .right_section_pres input:focus {
    background-color: #bd095a;
    color: #ffffff;
  }

  .registerButton {
    width: 30%;
    margin-top: 0px;
    font-size: 18px;
    height: 30px;
    margin-left: -15px;
  }
  .right_section_pres p {
    font-size: 25px;
  }
  .login_page {
    margin-bottom: -130px;
  }
}
@media only screen and (max-width: 377px) {
}

.pres_container {
  display: grid;
  height: auto;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  padding-top: 20px;
}
.registerLogo img {
  width: 15%;
  /* height: 10px; */
}
.pres_container .left_section_pres_login {
  padding: 50px;
}
.pres_container .left_section_pres_login img {
  width: 70%;
  -webkit-align-items: right;
          align-items: right;
  margin-left: 70px;
}
.pres_container .left_section_pres_login h1 {
  color: #bd095a;
  /* font-weight: bold; */
  text-align: left;
  margin-left: 20%;
}
.pres_container .left_section_pres_login h4 {
  /* font-family: 'Kalpurush', sans-serif; */
  /* text-align: left; */
  /* margin-right: 100%; */
  /* width: 50px; */
  margin-left: 20%;
  text-align: left;
  font-size: 22px;
}
.pres_container .right_section_pres {
  border-left: 1px solid #bd095a;
  /* padding-left: 0%; */
  /* padding-top: 10%; */
  height: 300px;
  margin-top: 100px;
}

.pres_container .right_section_pres h4 {
  margin-top: 10px;
  text-align: left;
  margin-left: 33%;
}

.pres_container .right_section_pres input {
  background-color: #bd095a;
  color: #ffffff;
  font-size: 20px;
  border-radius: 2px;
  height: 40px;
  width: 230px;
  border: 2px solid #bd095a;
}
.pres_container .right_section_pres input:focus {
  background-color: #bd095a;
  color: #ffffff;
}

.registerButton {
  border-radius: 10px;
  width: 18%;
  margin-top: 15px;
  background-image: linear-gradient(to right, #6c0534, #cc0a61);
  color: white;
  border: 0;
}

@media only screen and (min-width: 1497px) {
}
@media only screen and (min-width: 1685px) {
}
@media only screen and (min-width: 1798px) {
  .pres_container .left_section_pres_login h1 {
    font-size: 50px;
  }
  .pres_container .left_section_pres_login h4 {
    font-size: 30px;
  }
  .pres_container .right_section_pres h4 {
    margin-top: 10px;
    text-align: left;
    margin-left: 35%;
    font-size: 30px;
  }

  .pres_container .right_section_pres input {
    font-size: 25px;
    border-radius: 3px;
    height: 40px;
    width: 260px;
  }

  .registerButton {
    font-size: 20px;
    height: 35px;
  }
  .right_section_pres p {
    font-size: 22px;
  }
}
@media only screen and (min-width: 1920px) {
  .pres_container .left_section_pres_login h1 {
    font-size: 53px;
  }
  .pres_container .left_section_pres_login h4 {
    font-size: 30px;
  }
  .pres_container .right_section_pres h4 {
    margin-top: 10px;
    text-align: left;
    margin-left: 35%;
    font-size: 30px;
  }

  .pres_container .right_section_pres input {
    font-size: 27px;
    height: 45px;
    width: 285px;
  }

  .registerButton {
    font-size: 20px;
    height: 37px;
  }
  .right_section_pres p {
    font-size: 23px;
  }
}
@media only screen and (min-width: 2049px) {
  .pres_container .left_section_pres_login h1 {
    font-size: 55px;
  }
  .pres_container .left_section_pres_login h4 {
    font-size: 33px;
  }
  .pres_container .right_section_pres h4 {
    margin-top: 10px;
    text-align: left;
    margin-left: 35%;
    font-size: 33px;
  }

  .pres_container .right_section_pres input {
    font-size: 27px;
    height: 45px;
    width: 295px;
  }

  .registerButton {
    font-size: 22px;
    height: 40px;
  }
  .right_section_pres p {
    font-size: 25px;
  }
}
@media only screen and (min-width: 2697px) {
}
@media only screen and (min-width: 4046px) {
}
@media only screen and (max-width: 1205px) {
}
@media only screen and (max-width: 769px) {
}
@media only screen and (max-width: 428px) {
  .pres_container {
    grid-template-columns: 1fr;
    padding-top: 0px;
  }
  .registerLogo img {
    width: 45%;
  }
  .pres_container .left_section_pres_login {
    padding: 0px;
    margin-left: -75px;
    margin-top: 30px;
    margin-bottom: -70px;
  }
  .pres_container .left_section_pres_login img {
    width: 50%;
  }
  .pres_container .left_section_pres_login h1 {
    font-size: 35px;
    text-align: center;
    margin-right: 14px;
  }
  .pres_container .left_section_pres_login h4 {
    font-size: 23px;
  }
  .pres_container .right_section_pres {
    border-left: none;
    margin-top: 100px;
    margin-left: 15px;
  }
  
  .pres_container .right_section_pres h4 {
    margin-top: 10px;
    text-align: left;
    margin-left: 31%;
    font-size: 25px;
  }
  
  .pres_container .right_section_pres input {
    font-size: 20px;
    border-radius: 2px;
    height: 40px;
    width: 260px;
    border: 2px solid #bd095a;
    margin-left: -15px;
  }
  .pres_container .right_section_pres input:focus {
    background-color: #bd095a;
    color: #ffffff;
  }
  
  .registerButton {
    width: 30%;
    margin-top: 0px;
    font-size: 18px;
    height: 30px;
    margin-left: -15px;
  }
  .right_section_pres p {
    font-size: 25px;
    padding: 10px;
    margin-left: -10px;
  }
  .login_page{
    margin-bottom: -130px;
  }
}
@media only screen and (max-width: 377px) {
}

/* .particularDoctor_headerBox img {
  height: 80px;
} */
.particularDoctor_main_container {
  width: 100%;
}
.particularDoctor_card_container {
  background-image: linear-gradient(#74175a, #04050d);
  border-radius: 15px;
  width: 90%;
  color: #ffffffff;
}
.particularDoctor_content {
  padding: 15px;
  height: 309px;
}
.particularDoctor_flex {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}
.particularDoctor_flex_image img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border: 2px solid #ffb903;
  margin-right: 30px;
}

.particularDoctor_flex_details {
  text-align: left;
}

.particularDoctor_flex_details h2 {
  font-size: 25px;
}
.particularDoctor_flex_details_designation {
  font-size: 15px;
  margin-top: -13px;
}
.particularDoctor_fees {
  width: 90%;
  border-radius: 5px;
  background-color: #cc0a61;
  margin-top: -8px;
  padding-left: 4px;
}
.particularDoctor_flex_details_time {
  font-size: 13px;
}
.particularDoctor_desc {
  -webkit-justify-content: center;
          justify-content: center;
  text-align: justify;
  font-size: 10px;
  margin-top: 4%;
}
.particularDoctor_description {
  text-decoration: underline;
  -webkit-text-decoration-color: rgba(173, 87, 87, 0.486);
          text-decoration-color: rgba(173, 87, 87, 0.486);
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

/* .particularDoctor_headerBox input {
  padding-left: 33px;
  padding-right: 33px;
  border-radius: 5px;
  border: 1px solid rgb(180, 180, 180);
  text-align: center;
  outline: none;
}
.particularDoctor_searchButton {
  width: 35px;
  height: 28px;
  border: 1px solid #ffcc43;
  background: #ffcc43;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  margin-left: -3px;
}
.particularDoctor_searchButton i:hover{
  transform: scale(-1, 1);
}
.particularDoctor_autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  left: 0;
  right: 0;
  width: 20%;
  left: 71%;
  background-color: #fff;
  height: 300px;
  overflow-y: auto;
}

.particularDoctor_autocomplete-items div {
  cursor: pointer;
  padding: 2px;
}

.particularDoctor_autocomplete-items div:hover {
  background-color: #e9e9e9;
}

.particularDoctor_items_serial {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #d4d4d4;
  line-height: 4px;
}
.particularDoctor_items_serial img {
  height: 85px;
  width: 80px;
}
.particularDoctor_items_serial_info {
  text-align: left;
}
.particularDoctor_items_serial_info #name {
  margin-top: 7px;
  font-size: 18px;
  color: #bd095a;
} */

@media only screen and (min-width: 1497px) {
}
@media only screen and (min-width: 1685px) {
}
@media only screen and (min-width: 1798px) {
  /* .particularDoctor_headerBox img {
    height: 120px;
    margin-left: -50px;
  }
  .particularDoctor_navbar_input {
    margin-right: -100px;
  }
  .particularDoctor_headerBox input {
    height: 40px;
    font-size: 24px;
    margin-top: 7px;
  }
  .particularDoctor_searchButton {
    width: 45px;
    height: 40px;
    font-size: 24px;
  }
  .particularDoctor_autocomplete-items {
    width: 21%;
    left: 1268px;
    height: 385px;
  }
  .particularDoctor_devider {
    border-bottom: 1px solid #d4d4d4;
  }
  .particularDoctor_items_serial {
    border-bottom: none;
    line-height: 4px;
    margin-left: 50px;
  }
  .particularDoctor_items_serial img {
    height: 115px;
    width: 115px;
  }
  .particularDoctor_items_serial_info {
    margin-left: 5px;
  }
  .particularDoctor_items_serial_info #name {
    margin-top: 10px;
    font-size: 23px;
  }
  .particularDoctor_items_serial_info p {
    font-size: 20px;
    line-height: 10px;
  } */
  .particularDoctor_main {
    width: 110%;
    margin-left: -50px;
  }
  .particularDoctor_main_container {
    width: 150%;
  }
  .particularDoctor_content {
    width: 100%;
    padding: 15px;
    height: 410px;
  }
  .particularDoctor_card_container {
    width: 90%;
  }
  .particularDoctor_flex {
  }
  .particularDoctor_flex_image img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin-right: 30px;
  }
  .particularDoctor_flex_details {
    text-align: left;
  }
  .particularDoctor_flex_details h2 {
    font-size: 35px;
  }
  .particularDoctor_flex_details_designation {
    font-size: 22px;
    margin-top: -13px;
  }
  .particularDoctor_fees {
    width: 90%;
    margin-top: -8px;
    padding-left: 4px;
  }
  .particularDoctor_fees h5 {
    font-size: 28px;
  }
  .particularDoctor_flex_details_time {
    font-size: 18px;
  }
  .particularDoctor_flex_details Button {
    font-size: 22px;
  }
  .particularDoctor_desc {
    font-size: 16px;
    padding: 10px;
  }
  .particularDoctor_desc p {
    font-size: 15px;
  }
  .particularDoctor_rating {
    -webkit-transform: scale(1.3, 1.3);
            transform: scale(1.3, 1.3);
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1920px) {
  /* .particularDoctor_autocomplete-items {
    width: 380px;
    left: 1330px;
    height: 390px;
  } */
}
@media only screen and (min-width: 2049px) {
  /* .particularDoctor_headerBox img {
    height: 150px;
    margin-left: -130px;
  }

  .particularDoctor_navbar_input {
    margin-right: -190px;
  }
  .particularDoctor_headerBox input {
    height: 40px;
    font-size: 24px;
    margin-top: 7px;
  }
  .particularDoctor_searchButton {
    width: 45px;
    height: 41px;
    font-size: 24px;
  }
  .particularDoctor_autocomplete-items {
    width: 380px;
    left: 1468px;
    height: 418px;
  }
  .particularDoctor_items_serial {
    border-bottom: none;
    line-height: 4px;
    margin-left: 130px;
  }
  .particularDoctor_items_serial img {
    height: 120px;
    width: 120px;
  }
  .particularDoctor_items_serial_info {
    margin-left: -5px;
  }
  .particularDoctor_items_serial_info #name {
    margin-top: 10px;
    font-size: 25px;
  }
  .particularDoctor_items_serial_info p {
    font-size: 22px;
    line-height: 13px;
  } */

  .particularDoctor_main {
    width: 125%;
    margin-left: -130px;
  }
  .particularDoctor_main_container {
    width: 200%;
  }
  .particularDoctor_content {
    width: 100%;
    padding: 15px;
    height: 450px;
  }
  .particularDoctor_card_container {
    width: 90%;
  }
  .particularDoctor_flex {
    margin-top: 10px;
  }
  .particularDoctor_flex_image img {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    margin-right: 30px;
  }
  .particularDoctor_flex_details {
    text-align: left;
  }
  .particularDoctor_flex_details h2 {
    font-size: 40px;
  }
  .particularDoctor_flex_details_designation {
    font-size: 22px;
    margin-top: -13px;
  }
  .particularDoctor_fees {
    width: 90%;
    margin-top: -8px;
    padding-left: 4px;
  }
  .particularDoctor_fees h5 {
    font-size: 30px;
  }
  .particularDoctor_flex_details_time {
    font-size: 22px;
  }
  .particularDoctor_flex_details Button {
    font-size: 25px;
  }
  .particularDoctor_desc {
    font-size: 16px;
    padding: 10px;
  }
  .particularDoctor_desc p {
    font-size: 16px;
  }
}
@media only screen and (min-width: 2697px) {
}
@media only screen and (min-width: 4046px) {
}
@media only screen and (max-width: 1205px) {
  .particularDoctor_main{
    margin-top: 70px;
    margin-bottom: 50px;
  }
  .particularDoctor_main_container {
    width: 100%;
  }
  .particularDoctor_card_container {
    background-image: linear-gradient(#74175a, #04050d);
    border-radius: 15px;
    width: 103%;
    color: #ffffffff;
  }
  .particularDoctor_content {
    padding: 10px;
    height: 315px;
  }
  
}
@media only screen and (max-width: 991px) {
  .particularDoctor_main{
    margin-top: 60px;
    margin-bottom: 50px;
  }
  .particularDoctor_main_container {
    width: 120%;
  }
  .particularDoctor_card_container {
    width: 108%;
  }
  .particularDoctor_content {
    padding: 8px;
    height: 309px;
  }
  .particularDoctor_flex_image img {
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }  
}
@media only screen and (max-width: 769px) {
  .particularDoctor_main{
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .particularDoctor_main_container {
    width: 110%;
  }
  .particularDoctor_card_container {
    width: 105%;
  }
  .particularDoctor_content {
    padding: 8px;
    height: 309px;
  }
  .particularDoctor_flex {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }
  .particularDoctor_flex_image img {
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .particularDoctor_main{
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .particularDoctor_main_container {
    width: 100%;
  }
  .particularDoctor_card_container {
    width: 90%;
  }
  .particularDoctor_content {
    padding: 8px;
    height: 309px;
  }
  .particularDoctor_flex {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }
  .particularDoctor_flex_image img {
    width: 150px;
    height: 150px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 428px) {
  /* .particularDoctor_headerBox input {
    margin-top: 20px;
    border-radius: 5px;
    border: 1px solid black;
    margin-left: 0px;
    width: 86%;
  }
  .particularDoctor_searchButton {
    width: 30px;
    height: 28px;
  }
  .particularDoctor_autocomplete-items {
    width: 55%;
    left: 170px;
    height: 280px;
  }
  .particularDoctor_devider {
    border-bottom: 1px solid #d4d4d4;
  }
  .particularDoctor_items_serial {
    border-bottom: none;
    line-height: 2px;
  }
  .particularDoctor_items_serial img {
    height: 80px;
    width: 70px;
  }
  .particularDoctor_items_serial_info {
    margin-left: -3px;
  }
  .particularDoctor_items_serial_info #name {
    margin-top: 10px;
    font-size: 19px;
  }
  .particularDoctor_items_serial_info p {
    font-size: 16px;
  } */

  .particularDoctor_flex_image img {
    width: 110px;
    height: 110px;
    margin-right: 10px;
  }

  .particularDoctor_navbar{
    display: grid;
    grid-template-rows: 7vh;
    margin-top: 2vh;
  }

  .particularDoctor_main {
    width: 100%;
    margin-left: 17px;
  }
}
@media only screen and (max-width: 377px) {
}

.loading {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    /* margin-left: 46%; */
    margin-top: 10%;
  }
  .loading div {
    position: absolute;
    background: #C70A5F;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .loading div:nth-child(2) {
    -webkit-animation-delay: -.7s;
            animation-delay: -.7s;
  }
  @-webkit-keyframes loading {
    0% {
      top: 28px;
      left: 28px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 58px;
      height: 58px;
      opacity: 0;
    }
  }
  @keyframes loading {
    0% {
      top: 28px;
      left: 28px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 58px;
      height: 58px;
      opacity: 0;
    }
  }

  @media only screen and (max-width: 428px) {

    .loading {
      display: inline-block;
      position: relative;
      width: 64px;
      height: 64px;
      margin-left: 42%;
      margin-top: 10%;
    }
  }
  
  
.bookingPaymentLoading_logo {
  height: 100px;
}

.bookingPaymentLoading_message {
  text-align: center;
  color: #cc0a61;
  font-family: poppins;
  font-weight: bold;
}

.schedule_container {
  font-family: poppins;
  display: grid;
  padding: 30px;
  grid-template-columns: 50% 50%;
  /* grid-template-columns: 45% 45%; */
  grid-template-rows: auto;
  grid-gap: 10px;
}

.success_doctor_left > h1,
.success_doctor_right > h6 {
  text-align: center;
  color: #bd095a;
  font-weight: bold;
}

.success_doctor_right {
  border-left: 0.5px solid #bd095a;
  /* padding-top: 19%; */
  padding-top: 10%;
}

.success_doctor_left img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.success_doctor_right img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 14%;
}

.bookingPaymentFailure_logo {
  height: 100px;
}
.bookingPaymentFailure_schedule_container {
  font-family: poppins;
  display: grid;
  padding: 30px;
  grid-template-columns: 50% 50%;
  /* grid-template-columns: 45% 45%; */
  grid-template-rows: auto;
  grid-gap: 10px;
}
.bookingPaymentFailure_doctor_left > h1,
.bookingPaymentFailure_doctor_right > h6 {
  text-align: center;
  color: #bd095a;
  font-weight: bold;
}
.bookingPaymentFailure_doctor_right {
  border-left: 0.5px solid #bd095a;
  /* padding-top: 19%; */
  padding-top: 10%;
}
.bookingPaymentFailure_doctor_left img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 45%;
}
.bookingPaymentFailure_doctor_right img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 14%;
}
.bookingPaymentSuccess_logo {
  height: 100px;
}
.bookingPaymentSuccess_schedule_container {
  font-family: poppins;
  display: grid;
  padding: 30px;
  grid-template-columns: 50% 50%;
  /* grid-template-columns: 45% 45%; */
  grid-template-rows: auto;
  grid-gap: 10px;
}
.bookingPaymentSuccess_doctor_left > h1,
.bookingPaymentSuccess_doctor_right > h6 {
  text-align: center;
  color: #bd095a;
  font-weight: bold;
}
.bookingPaymentSuccess_doctor_right {
  border-left: 0.5px solid #bd095a;
  /* padding-top: 19%; */
  padding-top: 10%;
}
.bookingPaymentSuccess_doctor_left img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 45%;
}
.bookingPaymentSuccess_doctor_right img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 14%;
}
.bookingPaymentCancelled_logo {
  height: 100px;
}
.bookingPaymentCancelled_schedule_container {
  font-family: poppins;
  display: grid;
  padding: 30px;
  grid-template-columns: 50% 50%;
  /* grid-template-columns: 45% 45%; */
  grid-template-rows: auto;
  grid-gap: 10px;
}
.bookingPaymentCancelled_doctor_left > h1,
.bookingPaymentCancelled_doctor_right > h6 {
  text-align: center;
  color: #bd095a;
  font-weight: bold;
}
.bookingPaymentCancelled_doctor_right {
  border-left: 0.5px solid #bd095a;
  /* padding-top: 19%; */
  padding-top: 10%;
}
.bookingPaymentCancelled_doctor_left img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 45%;
}
.bookingPaymentCancelled_doctor_right img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 14%;
}

@media only screen and (min-width: 1497px) {
}
@media only screen and (min-width: 1685px) {
}
@media only screen and (min-width: 1798px) {
  .bookingPaymentLoading_logo {
    height: 130px;
  }
  .bookingPaymentLoading_message {
    font-size: 35px;
  }
  .bookingPaymentFailure_logo {
    height: 130px;
  }
  .bookingPaymentFailure_schedule_container {
    padding: 40px;
    margin-top: 20px;
  }
  .bookingPaymentFailure_doctor_right > h6 {
    font-size: 22px;
  }
  .bookingPaymentFailure_doctor_right {
    padding-top: 10%;
  }
  .bookingPaymentFailure_doctor_left img {
    width: 55%;
  }
  .bookingPaymentFailure_doctor_right img {
    width: 15%;
  }
  .bookingPaymentSuccess_logo {
    height: 130px;
  }
  .bookingPaymentSuccess_schedule_container {
    padding: 40px;
    margin-top: 20px;
  }
  .bookingPaymentSuccess_doctor_right > h6 {
    font-size: 22px;
  }
  .bookingPaymentSuccess_doctor_right {
    padding-top: 10%;
  }
  .bookingPaymentSuccess_doctor_left img {
    width: 55%;
  }
  .bookingPaymentSuccess_doctor_right img {
    width: 15%;
  }

  .bookingPaymentCancelled_logo {
    height: 130px;
  }
  .bookingPaymentCancelled_schedule_container {
    padding: 40px;
    margin-top: 20px;
  }
  .bookingPaymentCancelled_doctor_right > h6 {
    font-size: 22px;
  }
  .bookingPaymentCancelled_doctor_right {
    padding-top: 10%;
  }
  .bookingPaymentCancelled_doctor_left img {
    width: 55%;
  }
  .bookingPaymentCancelled_doctor_right img {
    width: 15%;
  }
}
@media only screen and (min-width: 1920px) {
  .bookingPaymentLoading_logo {
    height: 145px;
    margin-bottom: 30px;
  }
  .bookingPaymentLoading_message {
    margin-top: 15px;
    font-size: 38px;
  }
}
@media only screen and (min-width: 2049px) {
  .bookingPaymentLoading_logo {
    height: 160px;
    margin-bottom: 30px;
  }
  .bookingPaymentLoading_message {
    margin-top: 20px;
    font-size: 40px;
  }
  .bookingPaymentFailure_logo {
    height: 160px;
  }
  .bookingPaymentFailure_schedule_container {
    padding: 50px;
    margin-top: 30px;
  }
  .bookingPaymentFailure_doctor_right > h6 {
    font-size: 25px;
  }
  .bookingPaymentFailure_doctor_right {
    padding-top: 10%;
  }
  .bookingPaymentFailure_doctor_left img {
    width: 60%;
  }
  .bookingPaymentFailure_doctor_right img {
    width: 16%;
  }
  .bookingPaymentSuccess_logo {
    height: 160px;
  }
  .bookingPaymentSuccess_schedule_container {
    padding: 50px;
    margin-top: 30px;
  }
  .bookingPaymentSuccess_doctor_right > h6 {
    font-size: 25px;
  }
  .bookingPaymentSuccess_doctor_right {
    padding-top: 10%;
  }
  .bookingPaymentSuccess_doctor_left img {
    width: 60%;
  }
  .bookingPaymentSuccess_doctor_right img {
    width: 16%;
  }

  .bookingPaymentCancelled_logo {
    height: 160px;
  }
  .bookingPaymentCancelled_schedule_container {
    padding: 50px;
    margin-top: 30px;
  }
  .bookingPaymentCancelled_doctor_right > h6 {
    font-size: 25px;
  }
  .bookingPaymentCancelled_doctor_right {
    padding-top: 10%;
  }
  .bookingPaymentCancelled_doctor_left img {
    width: 60%;
  }
  .bookingPaymentCancelled_doctor_right img {
    width: 16%;
  }
}
@media only screen and (min-width: 2697px) {
}
@media only screen and (min-width: 4046px) {
}
@media only screen and (max-width: 1205px) {
}
@media only screen and (max-width: 769px) {
}
@media only screen and (max-width: 428px) {
}
@media only screen and (max-width: 377px) {
}

.dropdown_container_patientProfile ul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 3px;
  margin-right: 7px;
  margin-top: -3px;
  margin-bottom: 3px;
}
.menu_button_patientProfile {
  border-radius: 5px;
  width: 100%;
  font-size: small;
  background-color: #d4377d;
  color: white;
  border: 1px solid #d4377d;
  margin-left: 3px;
  /* margin-right: 3px; */
  margin-bottom: 3px;
  margin-top: 2px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.profile_page {
  display: grid;
  grid-template-columns: auto 30% 60% auto;
  grid-template-rows: auto;
  grid-gap: 0px;
  background-color: #ffff;
  font-family: poppins;
}
.profile_page form {
  width: 100%;
}
.profile_form {
  width: 80%;
  padding: 10px;
}
.form-data {
  width: 80%;
}
.profile_status_title {
  color: #cc0a61;
  text-align: center;
  font-weight: bolder;
  font-size: 25px;
}
.profile_order_title {
  color: #cc0a61;
  text-align: center;
  font-weight: bolder;
  font-size: 25px;
  padding: 10px;
}
.profile_status {
  background-color: #ebe8e9;
  border-radius: 5px;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.profile_status_flex {
  display: -webkit-flex;
  display: flex;
}
.profile_status_flex h4 {
  padding-left: 5px;
  font-size: medium;
  font-weight: bolder;
  margin: 0 0 0 0;
}
.profile_order_header th {
  font-weight: bolder;
}
@media only screen and (max-width: 428px) {
  .dropdown_container_patientProfile ul {
    margin-top: -9px;
    margin-bottom: 1px;
  }
  .menu_button_patientProfile {
    margin-bottom: -5px;
    margin-top: -8px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  
  .profile_page {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    /* grid-gap: 35px; */
    background-color: #ffff;
    /* width: 70%; */
  }
  .profile_page form {
    width: 100%;
  }
  .profile_form {
    width: 100%;
  }
  .profile_status_title {
    text-align: center;
    font-weight: bolder;
    font-size: 25px;
  }
  .profile_status {
    height: 50px;
    width: 100% !important;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  .profile_order_title {
    color: #cc0a61;
    text-align: center;
    font-weight: bolder;
    font-size: 25px;
    padding: 10px;
  }
  .profile_status {
    width: 83%;
  }
  .profile_status_flex h4 {
    font-size: 18px;
  }
  .MuiTableContainer-root {
    width: 411px !important;
    overflow: hidden;
  }
  .profile_order_header th {
    font-size: 18px;
  }
  .table_data td {
    font-size: 18px;
  }
  .table_button button {
    font-size: 18px;
  }
}

.footer_navbar {
  -webkit-justify-content: center !important;
          justify-content: center !important;
}
.footer_navbar img {
  cursor: pointer;
  width: 85%;
}
.about_us {
  text-align: justify;
  /* width: 80%; */
  display: grid;
  grid-template-columns: 80%;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 1%;
  margin-bottom: 2%;
  font-family: "poppins";
}
.about_us h2 {
  color: #cc0a61;
  font-weight: 800;
}
.about_us h3 {
  color: #cc0a61;
  font-weight: 800;
}
.about_us h4 {
  color: #cc0a61;
  font-weight: 800;
}
.about_us p {
  font-weight: 600;
  /* padding:5px 5px 5px 5px; */
}

#me {
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 7% 1% 5% 5%;
}

#me div {
  border-radius: 20px !important;
  background-color: white !important;
}

#me video {
  height: 30em !important;
  position: relative !important;
}

#remote-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 7% 5% 5% 1%;
}

#remote-container div {
  border-radius: 20px !important;
  background-color: white !important;
}

#remote-container video {
  height: 30em !important;
  position: relative !important;
}

@media (max-width: 767.98px) {
  #remote-container {
    padding: 0 0 0 0;
  }
  #me {
    padding: 10px 0 0 0;
  }
  #remote-container div {
    border-radius: 10px !important;
  }
  #me div {
    border-radius: 10px !important;
  }
  #remote-container video {
    height: 20em !important;
  }
  #me video {
    height: 20em !important;
  }
}

