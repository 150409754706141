.dropdown_container_patientProfile ul {
  display: flex;
  flex-direction: column;
  padding-left: 3px;
  margin-right: 7px;
  margin-top: -3px;
  margin-bottom: 3px;
}
.menu_button_patientProfile {
  border-radius: 5px;
  width: 100%;
  font-size: small;
  background-color: #d4377d;
  color: white;
  border: 1px solid #d4377d;
  margin-left: 3px;
  /* margin-right: 3px; */
  margin-bottom: 3px;
  margin-top: 2px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.profile_page {
  display: grid;
  grid-template-columns: auto 30% 60% auto;
  grid-template-rows: auto;
  grid-gap: 0px;
  background-color: #ffff;
  font-family: poppins;
}
.profile_page form {
  width: 100%;
}
.profile_form {
  width: 80%;
  padding: 10px;
}
.form-data {
  width: 80%;
}
.profile_status_title {
  color: #cc0a61;
  text-align: center;
  font-weight: bolder;
  font-size: 25px;
}
.profile_order_title {
  color: #cc0a61;
  text-align: center;
  font-weight: bolder;
  font-size: 25px;
  padding: 10px;
}
.profile_status {
  background-color: #ebe8e9;
  border-radius: 5px;
  height: 60px;
  display: flex;
  align-items: center;
}
.profile_status_flex {
  display: flex;
}
.profile_status_flex h4 {
  padding-left: 5px;
  font-size: medium;
  font-weight: bolder;
  margin: 0 0 0 0;
}
.profile_order_header th {
  font-weight: bolder;
}
@media only screen and (max-width: 428px) {
  .dropdown_container_patientProfile ul {
    margin-top: -9px;
    margin-bottom: 1px;
  }
  .menu_button_patientProfile {
    margin-bottom: -5px;
    margin-top: -8px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  
  .profile_page {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    /* grid-gap: 35px; */
    background-color: #ffff;
    /* width: 70%; */
  }
  .profile_page form {
    width: 100%;
  }
  .profile_form {
    width: 100%;
  }
  .profile_status_title {
    text-align: center;
    font-weight: bolder;
    font-size: 25px;
  }
  .profile_status {
    height: 50px;
    width: 100% !important;
    display: flex;
    align-items: center;
  }
  .profile_order_title {
    color: #cc0a61;
    text-align: center;
    font-weight: bolder;
    font-size: 25px;
    padding: 10px;
  }
  .profile_status {
    width: 83%;
  }
  .profile_status_flex h4 {
    font-size: 18px;
  }
  .MuiTableContainer-root {
    width: 411px !important;
    overflow: hidden;
  }
  .profile_order_header th {
    font-size: 18px;
  }
  .table_data td {
    font-size: 18px;
  }
  .table_button button {
    font-size: 18px;
  }
}
