.bookingPaymentLoading_logo {
  height: 100px;
}

.bookingPaymentLoading_message {
  text-align: center;
  color: #cc0a61;
  font-family: poppins;
  font-weight: bold;
}

.schedule_container {
  font-family: poppins;
  display: grid;
  padding: 30px;
  grid-template-columns: 50% 50%;
  /* grid-template-columns: 45% 45%; */
  grid-template-rows: auto;
  grid-gap: 10px;
}

.success_doctor_left > h1,
.success_doctor_right > h6 {
  text-align: center;
  color: #bd095a;
  font-weight: bold;
}

.success_doctor_right {
  border-left: 0.5px solid #bd095a;
  /* padding-top: 19%; */
  padding-top: 10%;
}

.success_doctor_left img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.success_doctor_right img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 14%;
}

.bookingPaymentFailure_logo {
  height: 100px;
}
.bookingPaymentFailure_schedule_container {
  font-family: poppins;
  display: grid;
  padding: 30px;
  grid-template-columns: 50% 50%;
  /* grid-template-columns: 45% 45%; */
  grid-template-rows: auto;
  grid-gap: 10px;
}
.bookingPaymentFailure_doctor_left > h1,
.bookingPaymentFailure_doctor_right > h6 {
  text-align: center;
  color: #bd095a;
  font-weight: bold;
}
.bookingPaymentFailure_doctor_right {
  border-left: 0.5px solid #bd095a;
  /* padding-top: 19%; */
  padding-top: 10%;
}
.bookingPaymentFailure_doctor_left img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 45%;
}
.bookingPaymentFailure_doctor_right img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 14%;
}
.bookingPaymentSuccess_logo {
  height: 100px;
}
.bookingPaymentSuccess_schedule_container {
  font-family: poppins;
  display: grid;
  padding: 30px;
  grid-template-columns: 50% 50%;
  /* grid-template-columns: 45% 45%; */
  grid-template-rows: auto;
  grid-gap: 10px;
}
.bookingPaymentSuccess_doctor_left > h1,
.bookingPaymentSuccess_doctor_right > h6 {
  text-align: center;
  color: #bd095a;
  font-weight: bold;
}
.bookingPaymentSuccess_doctor_right {
  border-left: 0.5px solid #bd095a;
  /* padding-top: 19%; */
  padding-top: 10%;
}
.bookingPaymentSuccess_doctor_left img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 45%;
}
.bookingPaymentSuccess_doctor_right img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 14%;
}
.bookingPaymentCancelled_logo {
  height: 100px;
}
.bookingPaymentCancelled_schedule_container {
  font-family: poppins;
  display: grid;
  padding: 30px;
  grid-template-columns: 50% 50%;
  /* grid-template-columns: 45% 45%; */
  grid-template-rows: auto;
  grid-gap: 10px;
}
.bookingPaymentCancelled_doctor_left > h1,
.bookingPaymentCancelled_doctor_right > h6 {
  text-align: center;
  color: #bd095a;
  font-weight: bold;
}
.bookingPaymentCancelled_doctor_right {
  border-left: 0.5px solid #bd095a;
  /* padding-top: 19%; */
  padding-top: 10%;
}
.bookingPaymentCancelled_doctor_left img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 45%;
}
.bookingPaymentCancelled_doctor_right img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 14%;
}

@media only screen and (min-width: 1497px) {
}
@media only screen and (min-width: 1685px) {
}
@media only screen and (min-width: 1798px) {
  .bookingPaymentLoading_logo {
    height: 130px;
  }
  .bookingPaymentLoading_message {
    font-size: 35px;
  }
  .bookingPaymentFailure_logo {
    height: 130px;
  }
  .bookingPaymentFailure_schedule_container {
    padding: 40px;
    margin-top: 20px;
  }
  .bookingPaymentFailure_doctor_right > h6 {
    font-size: 22px;
  }
  .bookingPaymentFailure_doctor_right {
    padding-top: 10%;
  }
  .bookingPaymentFailure_doctor_left img {
    width: 55%;
  }
  .bookingPaymentFailure_doctor_right img {
    width: 15%;
  }
  .bookingPaymentSuccess_logo {
    height: 130px;
  }
  .bookingPaymentSuccess_schedule_container {
    padding: 40px;
    margin-top: 20px;
  }
  .bookingPaymentSuccess_doctor_right > h6 {
    font-size: 22px;
  }
  .bookingPaymentSuccess_doctor_right {
    padding-top: 10%;
  }
  .bookingPaymentSuccess_doctor_left img {
    width: 55%;
  }
  .bookingPaymentSuccess_doctor_right img {
    width: 15%;
  }

  .bookingPaymentCancelled_logo {
    height: 130px;
  }
  .bookingPaymentCancelled_schedule_container {
    padding: 40px;
    margin-top: 20px;
  }
  .bookingPaymentCancelled_doctor_right > h6 {
    font-size: 22px;
  }
  .bookingPaymentCancelled_doctor_right {
    padding-top: 10%;
  }
  .bookingPaymentCancelled_doctor_left img {
    width: 55%;
  }
  .bookingPaymentCancelled_doctor_right img {
    width: 15%;
  }
}
@media only screen and (min-width: 1920px) {
  .bookingPaymentLoading_logo {
    height: 145px;
    margin-bottom: 30px;
  }
  .bookingPaymentLoading_message {
    margin-top: 15px;
    font-size: 38px;
  }
}
@media only screen and (min-width: 2049px) {
  .bookingPaymentLoading_logo {
    height: 160px;
    margin-bottom: 30px;
  }
  .bookingPaymentLoading_message {
    margin-top: 20px;
    font-size: 40px;
  }
  .bookingPaymentFailure_logo {
    height: 160px;
  }
  .bookingPaymentFailure_schedule_container {
    padding: 50px;
    margin-top: 30px;
  }
  .bookingPaymentFailure_doctor_right > h6 {
    font-size: 25px;
  }
  .bookingPaymentFailure_doctor_right {
    padding-top: 10%;
  }
  .bookingPaymentFailure_doctor_left img {
    width: 60%;
  }
  .bookingPaymentFailure_doctor_right img {
    width: 16%;
  }
  .bookingPaymentSuccess_logo {
    height: 160px;
  }
  .bookingPaymentSuccess_schedule_container {
    padding: 50px;
    margin-top: 30px;
  }
  .bookingPaymentSuccess_doctor_right > h6 {
    font-size: 25px;
  }
  .bookingPaymentSuccess_doctor_right {
    padding-top: 10%;
  }
  .bookingPaymentSuccess_doctor_left img {
    width: 60%;
  }
  .bookingPaymentSuccess_doctor_right img {
    width: 16%;
  }

  .bookingPaymentCancelled_logo {
    height: 160px;
  }
  .bookingPaymentCancelled_schedule_container {
    padding: 50px;
    margin-top: 30px;
  }
  .bookingPaymentCancelled_doctor_right > h6 {
    font-size: 25px;
  }
  .bookingPaymentCancelled_doctor_right {
    padding-top: 10%;
  }
  .bookingPaymentCancelled_doctor_left img {
    width: 60%;
  }
  .bookingPaymentCancelled_doctor_right img {
    width: 16%;
  }
}
@media only screen and (min-width: 2697px) {
}
@media only screen and (min-width: 4046px) {
}
@media only screen and (max-width: 1205px) {
}
@media only screen and (max-width: 769px) {
}
@media only screen and (max-width: 428px) {
}
@media only screen and (max-width: 377px) {
}
