/* .particularDoctor_headerBox img {
  height: 80px;
} */
.particularDoctor_main_container {
  width: 100%;
}
.particularDoctor_card_container {
  background-image: linear-gradient(#74175a, #04050d);
  border-radius: 15px;
  width: 90%;
  color: #ffffffff;
}
.particularDoctor_content {
  padding: 15px;
  height: 309px;
}
.particularDoctor_flex {
  display: flex;
  justify-content: space-evenly;
}
.particularDoctor_flex_image img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border: 2px solid #ffb903;
  margin-right: 30px;
}

.particularDoctor_flex_details {
  text-align: left;
}

.particularDoctor_flex_details h2 {
  font-size: 25px;
}
.particularDoctor_flex_details_designation {
  font-size: 15px;
  margin-top: -13px;
}
.particularDoctor_fees {
  width: 90%;
  border-radius: 5px;
  background-color: #cc0a61;
  margin-top: -8px;
  padding-left: 4px;
}
.particularDoctor_flex_details_time {
  font-size: 13px;
}
.particularDoctor_desc {
  justify-content: center;
  text-align: justify;
  font-size: 10px;
  margin-top: 4%;
}
.particularDoctor_description {
  text-decoration: underline;
  text-decoration-color: rgba(173, 87, 87, 0.486);
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

/* .particularDoctor_headerBox input {
  padding-left: 33px;
  padding-right: 33px;
  border-radius: 5px;
  border: 1px solid rgb(180, 180, 180);
  text-align: center;
  outline: none;
}
.particularDoctor_searchButton {
  width: 35px;
  height: 28px;
  border: 1px solid #ffcc43;
  background: #ffcc43;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  margin-left: -3px;
}
.particularDoctor_searchButton i:hover{
  transform: scale(-1, 1);
}
.particularDoctor_autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  left: 0;
  right: 0;
  width: 20%;
  left: 71%;
  background-color: #fff;
  height: 300px;
  overflow-y: auto;
}

.particularDoctor_autocomplete-items div {
  cursor: pointer;
  padding: 2px;
}

.particularDoctor_autocomplete-items div:hover {
  background-color: #e9e9e9;
}

.particularDoctor_items_serial {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #d4d4d4;
  line-height: 4px;
}
.particularDoctor_items_serial img {
  height: 85px;
  width: 80px;
}
.particularDoctor_items_serial_info {
  text-align: left;
}
.particularDoctor_items_serial_info #name {
  margin-top: 7px;
  font-size: 18px;
  color: #bd095a;
} */

@media only screen and (min-width: 1497px) {
}
@media only screen and (min-width: 1685px) {
}
@media only screen and (min-width: 1798px) {
  /* .particularDoctor_headerBox img {
    height: 120px;
    margin-left: -50px;
  }
  .particularDoctor_navbar_input {
    margin-right: -100px;
  }
  .particularDoctor_headerBox input {
    height: 40px;
    font-size: 24px;
    margin-top: 7px;
  }
  .particularDoctor_searchButton {
    width: 45px;
    height: 40px;
    font-size: 24px;
  }
  .particularDoctor_autocomplete-items {
    width: 21%;
    left: 1268px;
    height: 385px;
  }
  .particularDoctor_devider {
    border-bottom: 1px solid #d4d4d4;
  }
  .particularDoctor_items_serial {
    border-bottom: none;
    line-height: 4px;
    margin-left: 50px;
  }
  .particularDoctor_items_serial img {
    height: 115px;
    width: 115px;
  }
  .particularDoctor_items_serial_info {
    margin-left: 5px;
  }
  .particularDoctor_items_serial_info #name {
    margin-top: 10px;
    font-size: 23px;
  }
  .particularDoctor_items_serial_info p {
    font-size: 20px;
    line-height: 10px;
  } */
  .particularDoctor_main {
    width: 110%;
    margin-left: -50px;
  }
  .particularDoctor_main_container {
    width: 150%;
  }
  .particularDoctor_content {
    width: 100%;
    padding: 15px;
    height: 410px;
  }
  .particularDoctor_card_container {
    width: 90%;
  }
  .particularDoctor_flex {
  }
  .particularDoctor_flex_image img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin-right: 30px;
  }
  .particularDoctor_flex_details {
    text-align: left;
  }
  .particularDoctor_flex_details h2 {
    font-size: 35px;
  }
  .particularDoctor_flex_details_designation {
    font-size: 22px;
    margin-top: -13px;
  }
  .particularDoctor_fees {
    width: 90%;
    margin-top: -8px;
    padding-left: 4px;
  }
  .particularDoctor_fees h5 {
    font-size: 28px;
  }
  .particularDoctor_flex_details_time {
    font-size: 18px;
  }
  .particularDoctor_flex_details Button {
    font-size: 22px;
  }
  .particularDoctor_desc {
    font-size: 16px;
    padding: 10px;
  }
  .particularDoctor_desc p {
    font-size: 15px;
  }
  .particularDoctor_rating {
    transform: scale(1.3, 1.3);
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1920px) {
  /* .particularDoctor_autocomplete-items {
    width: 380px;
    left: 1330px;
    height: 390px;
  } */
}
@media only screen and (min-width: 2049px) {
  /* .particularDoctor_headerBox img {
    height: 150px;
    margin-left: -130px;
  }

  .particularDoctor_navbar_input {
    margin-right: -190px;
  }
  .particularDoctor_headerBox input {
    height: 40px;
    font-size: 24px;
    margin-top: 7px;
  }
  .particularDoctor_searchButton {
    width: 45px;
    height: 41px;
    font-size: 24px;
  }
  .particularDoctor_autocomplete-items {
    width: 380px;
    left: 1468px;
    height: 418px;
  }
  .particularDoctor_items_serial {
    border-bottom: none;
    line-height: 4px;
    margin-left: 130px;
  }
  .particularDoctor_items_serial img {
    height: 120px;
    width: 120px;
  }
  .particularDoctor_items_serial_info {
    margin-left: -5px;
  }
  .particularDoctor_items_serial_info #name {
    margin-top: 10px;
    font-size: 25px;
  }
  .particularDoctor_items_serial_info p {
    font-size: 22px;
    line-height: 13px;
  } */

  .particularDoctor_main {
    width: 125%;
    margin-left: -130px;
  }
  .particularDoctor_main_container {
    width: 200%;
  }
  .particularDoctor_content {
    width: 100%;
    padding: 15px;
    height: 450px;
  }
  .particularDoctor_card_container {
    width: 90%;
  }
  .particularDoctor_flex {
    margin-top: 10px;
  }
  .particularDoctor_flex_image img {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    margin-right: 30px;
  }
  .particularDoctor_flex_details {
    text-align: left;
  }
  .particularDoctor_flex_details h2 {
    font-size: 40px;
  }
  .particularDoctor_flex_details_designation {
    font-size: 22px;
    margin-top: -13px;
  }
  .particularDoctor_fees {
    width: 90%;
    margin-top: -8px;
    padding-left: 4px;
  }
  .particularDoctor_fees h5 {
    font-size: 30px;
  }
  .particularDoctor_flex_details_time {
    font-size: 22px;
  }
  .particularDoctor_flex_details Button {
    font-size: 25px;
  }
  .particularDoctor_desc {
    font-size: 16px;
    padding: 10px;
  }
  .particularDoctor_desc p {
    font-size: 16px;
  }
}
@media only screen and (min-width: 2697px) {
}
@media only screen and (min-width: 4046px) {
}
@media only screen and (max-width: 1205px) {
  .particularDoctor_main{
    margin-top: 70px;
    margin-bottom: 50px;
  }
  .particularDoctor_main_container {
    width: 100%;
  }
  .particularDoctor_card_container {
    background-image: linear-gradient(#74175a, #04050d);
    border-radius: 15px;
    width: 103%;
    color: #ffffffff;
  }
  .particularDoctor_content {
    padding: 10px;
    height: 315px;
  }
  
}
@media only screen and (max-width: 991px) {
  .particularDoctor_main{
    margin-top: 60px;
    margin-bottom: 50px;
  }
  .particularDoctor_main_container {
    width: 120%;
  }
  .particularDoctor_card_container {
    width: 108%;
  }
  .particularDoctor_content {
    padding: 8px;
    height: 309px;
  }
  .particularDoctor_flex_image img {
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }  
}
@media only screen and (max-width: 769px) {
  .particularDoctor_main{
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .particularDoctor_main_container {
    width: 110%;
  }
  .particularDoctor_card_container {
    width: 105%;
  }
  .particularDoctor_content {
    padding: 8px;
    height: 309px;
  }
  .particularDoctor_flex {
    display: flex;
    justify-content: space-evenly;
  }
  .particularDoctor_flex_image img {
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .particularDoctor_main{
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .particularDoctor_main_container {
    width: 100%;
  }
  .particularDoctor_card_container {
    width: 90%;
  }
  .particularDoctor_content {
    padding: 8px;
    height: 309px;
  }
  .particularDoctor_flex {
    display: flex;
    justify-content: space-evenly;
  }
  .particularDoctor_flex_image img {
    width: 150px;
    height: 150px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 428px) {
  /* .particularDoctor_headerBox input {
    margin-top: 20px;
    border-radius: 5px;
    border: 1px solid black;
    margin-left: 0px;
    width: 86%;
  }
  .particularDoctor_searchButton {
    width: 30px;
    height: 28px;
  }
  .particularDoctor_autocomplete-items {
    width: 55%;
    left: 170px;
    height: 280px;
  }
  .particularDoctor_devider {
    border-bottom: 1px solid #d4d4d4;
  }
  .particularDoctor_items_serial {
    border-bottom: none;
    line-height: 2px;
  }
  .particularDoctor_items_serial img {
    height: 80px;
    width: 70px;
  }
  .particularDoctor_items_serial_info {
    margin-left: -3px;
  }
  .particularDoctor_items_serial_info #name {
    margin-top: 10px;
    font-size: 19px;
  }
  .particularDoctor_items_serial_info p {
    font-size: 16px;
  } */

  .particularDoctor_flex_image img {
    width: 110px;
    height: 110px;
    margin-right: 10px;
  }

  .particularDoctor_navbar{
    display: grid;
    grid-template-rows: 7vh;
    margin-top: 2vh;
  }

  .particularDoctor_main {
    width: 100%;
    margin-left: 17px;
  }
}
@media only screen and (max-width: 377px) {
}
