#me {
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 7% 1% 5% 5%;
}

#me div {
  border-radius: 20px !important;
  background-color: white !important;
}

#me video {
  height: 30em !important;
  position: relative !important;
}

#remote-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 7% 5% 5% 1%;
}

#remote-container div {
  border-radius: 20px !important;
  background-color: white !important;
}

#remote-container video {
  height: 30em !important;
  position: relative !important;
}

@media (max-width: 767.98px) {
  #remote-container {
    padding: 0 0 0 0;
  }
  #me {
    padding: 10px 0 0 0;
  }
  #remote-container div {
    border-radius: 10px !important;
  }
  #me div {
    border-radius: 10px !important;
  }
  #remote-container video {
    height: 20em !important;
  }
  #me video {
    height: 20em !important;
  }
}
