.pres_container {
  display: grid;
  height: auto;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  padding-top: 20px;
}
.registerLogo img {
  width: 15%;
  /* height: 10px; */
}
.pres_container .left_section_pres_login {
  padding: 50px;
}
.pres_container .left_section_pres_login img {
  width: 70%;
  align-items: right;
  margin-left: 70px;
}
.pres_container .left_section_pres_login h1 {
  color: #bd095a;
  /* font-weight: bold; */
  text-align: left;
  margin-left: 20%;
}
.pres_container .left_section_pres_login h4 {
  /* font-family: 'Kalpurush', sans-serif; */
  /* text-align: left; */
  /* margin-right: 100%; */
  /* width: 50px; */
  margin-left: 20%;
  text-align: left;
  font-size: 22px;
}
.pres_container .right_section_pres {
  border-left: 1px solid #bd095a;
  /* padding-left: 0%; */
  /* padding-top: 10%; */
  height: 300px;
  margin-top: 100px;
}

.pres_container .right_section_pres h4 {
  margin-top: 10px;
  text-align: left;
  margin-left: 33%;
}

.pres_container .right_section_pres input {
  background-color: #bd095a;
  color: #ffffff;
  font-size: 20px;
  border-radius: 2px;
  height: 40px;
  width: 230px;
  border: 2px solid #bd095a;
}
.pres_container .right_section_pres input:focus {
  background-color: #bd095a;
  color: #ffffff;
}

.registerButton {
  border-radius: 10px;
  width: 18%;
  margin-top: 15px;
  background-image: linear-gradient(to right, #6c0534, #cc0a61);
  color: white;
  border: 0;
}

@media only screen and (min-width: 1497px) {
}
@media only screen and (min-width: 1685px) {
}
@media only screen and (min-width: 1798px) {
  .pres_container .left_section_pres_login h1 {
    font-size: 50px;
  }
  .pres_container .left_section_pres_login h4 {
    font-size: 30px;
  }
  .pres_container .right_section_pres h4 {
    margin-top: 10px;
    text-align: left;
    margin-left: 35%;
    font-size: 30px;
  }

  .pres_container .right_section_pres input {
    font-size: 25px;
    border-radius: 3px;
    height: 40px;
    width: 260px;
  }

  .registerButton {
    font-size: 20px;
    height: 35px;
  }
  .right_section_pres p {
    font-size: 22px;
  }
}
@media only screen and (min-width: 1920px) {
  .pres_container .left_section_pres_login h1 {
    font-size: 53px;
  }
  .pres_container .left_section_pres_login h4 {
    font-size: 30px;
  }
  .pres_container .right_section_pres h4 {
    margin-top: 10px;
    text-align: left;
    margin-left: 35%;
    font-size: 30px;
  }

  .pres_container .right_section_pres input {
    font-size: 27px;
    height: 45px;
    width: 285px;
  }

  .registerButton {
    font-size: 20px;
    height: 37px;
  }
  .right_section_pres p {
    font-size: 23px;
  }
}
@media only screen and (min-width: 2049px) {
  .pres_container .left_section_pres_login h1 {
    font-size: 55px;
  }
  .pres_container .left_section_pres_login h4 {
    font-size: 33px;
  }
  .pres_container .right_section_pres h4 {
    margin-top: 10px;
    text-align: left;
    margin-left: 35%;
    font-size: 33px;
  }

  .pres_container .right_section_pres input {
    font-size: 27px;
    height: 45px;
    width: 295px;
  }

  .registerButton {
    font-size: 22px;
    height: 40px;
  }
  .right_section_pres p {
    font-size: 25px;
  }
}
@media only screen and (min-width: 2697px) {
}
@media only screen and (min-width: 4046px) {
}
@media only screen and (max-width: 1205px) {
}
@media only screen and (max-width: 769px) {
}
@media only screen and (max-width: 428px) {
  .pres_container {
    grid-template-columns: 1fr;
    padding-top: 0px;
  }
  .registerLogo img {
    width: 45%;
  }
  .pres_container .left_section_pres_login {
    padding: 0px;
    margin-left: -75px;
    margin-top: 30px;
    margin-bottom: -70px;
  }
  .pres_container .left_section_pres_login img {
    width: 50%;
  }
  .pres_container .left_section_pres_login h1 {
    font-size: 35px;
    text-align: center;
    margin-right: 14px;
  }
  .pres_container .left_section_pres_login h4 {
    font-size: 23px;
  }
  .pres_container .right_section_pres {
    border-left: none;
    margin-top: 100px;
    margin-left: 15px;
  }
  
  .pres_container .right_section_pres h4 {
    margin-top: 10px;
    text-align: left;
    margin-left: 31%;
    font-size: 25px;
  }
  
  .pres_container .right_section_pres input {
    font-size: 20px;
    border-radius: 2px;
    height: 40px;
    width: 260px;
    border: 2px solid #bd095a;
    margin-left: -15px;
  }
  .pres_container .right_section_pres input:focus {
    background-color: #bd095a;
    color: #ffffff;
  }
  
  .registerButton {
    width: 30%;
    margin-top: 0px;
    font-size: 18px;
    height: 30px;
    margin-left: -15px;
  }
  .right_section_pres p {
    font-size: 25px;
    padding: 10px;
    margin-left: -10px;
  }
  .login_page{
    margin-bottom: -130px;
  }
}
@media only screen and (max-width: 377px) {
}
