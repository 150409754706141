.footer_navbar {
  justify-content: center !important;
}
.footer_navbar img {
  cursor: pointer;
  width: 85%;
}
.about_us {
  text-align: justify;
  /* width: 80%; */
  display: grid;
  grid-template-columns: 80%;
  justify-content: center;
  margin-top: 1%;
  margin-bottom: 2%;
  font-family: "poppins";
}
.about_us h2 {
  color: #cc0a61;
  font-weight: 800;
}
.about_us h3 {
  color: #cc0a61;
  font-weight: 800;
}
.about_us h4 {
  color: #cc0a61;
  font-weight: 800;
}
.about_us p {
  font-weight: 600;
  /* padding:5px 5px 5px 5px; */
}
