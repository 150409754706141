.headerBoxHome {
  height: 110vh;
  /* height: min-content; */
  position: relative;
  background-image: url("../../media/cover-01.png");
  background-size: cover;
}
.search_design {
  display: grid;
  grid-template-columns: auto auto auto;
  /* grid-template-rows: 100px; */
  /* grid-template-rows: 180px; */
  grid-template-rows: 20vh;
  /* grid-template-columns: 100%; */
  row-gap: 1%;
  column-gap: 5%;
  padding-top: 10px;
}

.landingLogo1 {
  cursor: pointer;
  height: 20vh;
  /* height: 100%; */
  margin-left: -20%;
}

.search {
  width: 100%;
  position: relative;
  display: flex;
}

.searchTerm {
  width: 100%;
  border: 1px solid #ffcc43;
  border-right: none;
  padding: 5px;
  height: 5vh;
  /* height: 32px; */
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;
}

.searchTerm:focus {
  color: #000000;
}

.searchButton1 {
  width: 5vh;
  /* height: 32px; */
  height: 5vh;
  border: 1px solid #ffcc43;
  background: #ffcc43;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 2vh;
}

.wrap {
  width: 63%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.autocomplete-items1 {
  position: relative;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  height: 280px;
  /* height: 30vh; */
  overflow-y: auto;
  overflow-x: hidden;
  top: 100%;
  left: 0;
  right: 0;
  width: 62%;
  left: 19%;
  top: 52%;
  background-color: #fff;
  margin-top: -3vh;
}

.autocomplete-items1 div {
  cursor: pointer;
  padding: 2px;
  text-align: left;
}

.autocomplete-items1 div:hover {
  background-color: #e9e9e9;
}

.items_serial1 {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #d4d4d4;
  line-height: 12px;
}

.items_serial1 img {
  height: 83px;
  width: 83px;
}

@media only screen and (min-width: 1497px) {
}
@media only screen and (min-width: 1685px) {
}
@media only screen and (min-width: 1798px) {
}
@media only screen and (min-width: 1920px) {
}
@media only screen and (min-width: 2049px) {
}
@media only screen and (min-width: 2697px) {
}
@media only screen and (min-width: 4046px) {
}


/* @media only screen and (max-width: 1205px) {
  .headerBoxHome {
    height: 80vh;
  }
  .search_design {
    grid-template-columns: 15% 15% 50%;
    grid-template-rows: 100px;
    row-gap: 0%;
    column-gap: 10%;
    padding-top: 1%;
  }
  .landingLogo1 {
    margin-top: -10px;
    margin-left: 55px;
    height: 15vh;
  }
}

@media only screen and (max-width: 995px) {
  .headerBoxHome {
    height: 65vh;
  }
}

@media only screen and (max-width: 769px) {
  .headerBoxHome {
    height: 52vh;
  }
  .search_design {
    grid-template-columns: 15% 15% 50%;
    grid-template-rows: 80px;
    row-gap: 0%;
    column-gap: 10%;
    padding-top: 1%;
  }
  .landingLogo1 {
    margin-top: -10px;
    margin-left: 25px;
    height: 12vh;
  }
}

@media only screen and (max-width: 649px) {
  .headerBoxHome {
    height: 45vh;
  }
  .search_design {
    grid-template-columns: 5% 15% 70%;
    grid-template-rows: 50px;
    row-gap: 0%;
    column-gap: 5%;
    padding-top: 1%;
  }
  .landingLogo1 {
    margin-top: -10px;
    margin-left: 25px;
    height: 10vh;
  }
  .wrap {
    margin-left: 6vh;
  }
  .autocomplete-items1 {
    margin-left: 6vh;
  }
}

@media only screen and (max-width: 550px) {
  .headerBoxHome {
    height: 36vh;
  }
} */

@media only screen and (max-width: 1024px) {
  .headerBoxHome {
    height: 34em;
  }
  .search_design {
    grid-template-columns: 15% 15% 60%;
    grid-template-rows: 80px;
    row-gap: 0%;
    column-gap: 5%;
    padding-top: 1%;
  }
  .landingLogo1 {
    margin-top: -10px;
    margin-left: 25px;
    height: 8rem;
  }
}

@media only screen and (max-width: 769px) {
  .headerBoxHome {
    height: 29rem;
  }
  .search_design {
    grid-template-columns: 15% 15% 60%;
    grid-template-rows: 60px;
    row-gap: 0%;
    column-gap: 5%;
    padding-top: 1%;
  }
  .landingLogo1 {
    margin-top: -10px;
    margin-left: 25px;
    height: 6rem;
  }
}


@media only screen and (max-width: 428px) {
  .headerBoxHome {
    /* background-image: none;
    height: auto; */
    /* height: 510px; */
    /* height: 62vh; */
    height: 30vh;
  }
  .search_design {
    /* display: list-item; */
    display: grid;
    grid-template-columns: 5% 1% 84%;
    grid-template-rows: 1px;
    row-gap: 1%;
    column-gap: 5%;
    padding-top: 1%;
  }
  .landingLogo1 {
    margin-top: -10px;
    margin-left: 15px;
    height: 60px;
  }
  .search {
    padding-top: 10px;
  }
  .searchTerm {
    padding: 5px;
    height: 28px;
  }
  .searchButton1 {
    height: 28px;
    font-size: 18px;
  }
  .wrap {
    margin-left: 5vh;
    margin-top: 4%;
  }
  .autocomplete-items1 {
    margin-left: 5vh;
    margin-top: -19px;
  }
}
@media only screen and (max-width: 377px) {
}
