.modalStyle {
  margin-top: 10%;
  margin-left: 35%;
  margin-right: 35%;
  border: 1px solid #ccc8c8;
  border-radius: 40px;
  padding: 30px 10px 20px 10px;
  background-color: white;
  outline: none;
}

.feedback_text {
  text-decoration: underline;
  text-decoration-color: rgb(233, 30, 223);
  text-align: center;
  text-underline-offset: 5px;
  font-weight: bold;
}
.rating {
  align-items: center;
  align-content: center;
  margin-top: 10%;
  transform: scale(1.4, 1.4) !important;
}
.modal_form {
  margin-top: 10px;
  width: 140%;
  margin-left: -20%;
}
.modal_form h6 {
  font-weight: bold;
}
.modal_form button {
  margin-top: 5px;
  border-radius: 7px;
  border: 1px solid #ffb903;
  background-color: #ffb903;
  margin: 10px;
  font-weight: bold;
  padding: 0px 12px 0px 12px;
}

@media only screen and (min-width: 1497px) {
}
@media only screen and (min-width: 1685px) {
}
@media only screen and (min-width: 1798px) {
  .modalStyle {
    /* height: 425px; */
    /* margin-top: 12%; */
    margin-left: 35%;
    margin-right: 35%;
    padding: 30px 10px 20px 10px;
  }

  .feedback_text {
    text-underline-offset: 5px;
    font-size: 27px;
    font-weight: bold;
  }
  .rating {
    margin-top: 12%;
    transform: scale(1.9, 1.9) !important;
  }
  .modal_form {
    margin-top: 10px;
    width: 140%;
    margin-left: -20%;
  }
  .modal_form h6 {
    font-size: 22px;
    font-weight: bold;
  }
  .modal_form textarea {
    height: 100px;
  }
  .modal_form button {
    margin-top: 5px;
    margin: 25px;
    padding: 4px 20px 0px 20px;
    font-size: 20px;
  }
}
@media only screen and (min-width: 1920px) {
}
@media only screen and (min-width: 2049px) {
  .modalStyle {
    /* height: 490px; */
    /* margin-top: 12%; */
    margin-left: 35%;
    margin-right: 35%;
    padding: 35px 10px 20px 10px;
  }

  .feedback_text {
    text-underline-offset: 5px;
    font-size: 31px;
    font-weight: bold;
  }
  .rating {
    margin-top: 12%;
    transform: scale(2.2, 2.2) !important;
  }
  .modal_form {
    margin-top: 10px;
    width: 140%;
    margin-left: -20%;
  }
  .modal_form h6 {
    font-size: 25px;
    font-weight: bold;
  }
  .modal_form textarea {
    height: 120px;
  }
  .modal_form button {
    margin-top: 5px;
    margin: 25px;
    padding: 5px 22px 0px 22px;
    font-size: 23px;
  }
}
@media only screen and (min-width: 2697px) {
}
@media only screen and (min-width: 4046px) {
}
@media only screen and (max-width: 1205px) {
  .modalStyle {
    /* height: 490px; */
    margin-top: 20%;
    margin-left: 25%;
    margin-right: 25%;
    padding: 35px 10px 10px 10px;
  }
}
@media only screen and (max-width: 769px) {
  .modalStyle {
    /* height: 490px; */
    margin-top: 30%;
    margin-left: 15%;
    margin-right: 15%;
    padding: 35px 0px 10px 0px;
  }
}
@media only screen and (max-width: 428px) {
  .modalStyle {
    /* height: 490px; */
    margin-top: 30%;
    margin-left: 5%;
    margin-right: 5%;
    padding: 35px 10px 10px 10px;
  }

  .feedback_text {
    text-underline-offset: 5px;
    font-size: 25px;
    font-weight: bold;
  }
  .rating {
    margin-top: 7%;
    transform: scale(1.7, 1.7) !important;
  }
  .modal_form {
    margin-top: 20px;
    width: 100%;
    margin-left: 0%;
  }
  .modal_form h6 {
    font-size: 20px;
    margin-top: 10px;
  }
  .modal_form textarea {
    height: 100px;
  }
  .modal_form button {
    margin-top: 0px;
    margin: 15px;
    padding: 5px 22px 2px 22px;
    font-size: 20px;
  }
}
@media only screen and (max-width: 377px) {
}
